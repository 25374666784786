import React from 'react';

import PageLoader from '../../../../shared/components/pageLoader';
import {getAppliancesByParams, getAppliance} from '../../../../api/appliances';
import {getBitrate} from '../../../../api/sites';
import {getCameras} from '../../../../api/sensors';
import SiteSettingsWrapperNewNavigation from '../utils/SiteSettingsWrapperNewNavigation';

const SitePerformanceNewNavigation = () => (
  <SiteSettingsWrapperNewNavigation name="site-performance-page-content">
    {(selectedSite, reload) => {
      const match = {
        url: `/home/site-settings/devices?siteId=${selectedSite.id}`,
      };
      return (
        <PageLoader
          key="site-performance-new-nav"
          page={() => import('../sitePerformance')}
          reload={reload}
          resources={{
            site: () => Promise.resolve(selectedSite),
            appliances: async () => {
              const fetchedAppliances = (await getAppliancesByParams({
                siteId: selectedSite.id,
                active: true,
              })).results;
              return Promise.all(
                fetchedAppliances.map(async (a) => ({
                  ...(await getAppliance(a.id)),
                  cameraDiscovery: a.cameraDiscovery,
                })),
              );
            },
            match: () => Promise.resolve(match),
            averageBitrates: () => getBitrate(selectedSite.id),
            camerasAndSettings: async () => {
              const allCameras = await getCameras(selectedSite.id);
              return {allCameras};
            },
          }}
        />
      );
    }}
  </SiteSettingsWrapperNewNavigation>
);

export default SitePerformanceNewNavigation;
