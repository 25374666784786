import React, {useEffect} from 'react';
import {DateTime} from 'luxon';

import VideocamOutlined from '@mui/icons-material/VideocamOutlined';

import {useApi} from '../../../../shared/hooks';
import {isNewNavigationWebEnabled} from '../../../../shared/util/user';
import {getCamerasHealthSummary} from '../../../../api/hardwareHealth';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {openInOneTab} from '../../utils';
import DashboardStatusWidget from '../../../../shared/components/dashboardComponent/dashboardStatusWidget';

const CameraWidget = ({widgetConfig, currentUser}) => {
  const [camerasHealthSummary, loading, isError, getCamerasSummary] = useApi(
    getCamerasHealthSummary,
  );

  useEffect(
    () => {
      const currentTimestamp = DateTime.utc().toISO();
      const dayAgoTimestamp = DateTime.utc()
        .minus({
          hours: 24,
        })
        .toISO();
      getCamerasSummary(dayAgoTimestamp, currentTimestamp);
    },
    [getCamerasSummary],
  );

  const offlineChanges = camerasHealthSummary?.offlineChanges;
  const pathname = isNewNavigationWebEnabled(currentUser)
    ? '/home/system-health'
    : '/hardware-health';

  const content = {
    dualPanel: true,
    panels: [
      {
        title: 'Cameras Offline',
        divider: true,
        count: camerasHealthSummary?.offline,
        path: pathname,
        search: '?cameraStatus=offline',
        state: {fromDashboard: true},
        target: openInOneTab(currentUser),
        buttonText: 'Offline',
        message: `${offlineChanges} Offline in the last 24 hrs.`,
      },
      {
        icon: <VideocamOutlined />,
        title: 'Image Health',
        count: camerasHealthSummary?.impaired,
        path: pathname,
        search: '?impairedCameras=impaired',
        state: {fromDashboard: true},
        target: openInOneTab(currentUser),
        buttonText: 'Impaired',
      },
    ],
  };

  return (
    <DashboardStatusWidget
      id={widgetConfig.widgetReference}
      altContent={content}
      isError={isError}
      loading={loading}
      {...widgetConfig.properties}
    />
  );
};

export default withCurrentUser(CameraWidget);
