import React, {createContext, useState} from 'react';

export const TopNavigationContext = createContext();

export const TopNavigationProvider = ({children}) => {
  const [title, setTitle] = useState('');
  const [backLink, setBackLink] = useState('');

  return (
    <TopNavigationContext.Provider
      value={{title, setTitle, backLink, setBackLink}}
    >
      {children}
    </TopNavigationContext.Provider>
  );
};
