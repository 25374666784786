import React from 'react';
import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import LoadablePromise from '../../../../shared/components/loadablePromise';
import FormBuilder from '../../../../shared/components/formBuilder';

/** format form entries so request payload is supported by the patch endpoint */
const formatPatchSubmition = (fields) =>
  Object.entries(fields).reduce((acc, current) => {
    const [key, value] = current;
    if (!value) {
      if (!Array.isArray(acc.unset)) acc.unset = [];
      acc.unset.push(key);
    } else acc.set = {...acc.set, [key]: value};
    return acc;
  }, {});

/** dialog with form for additional security related information */
const NoonlightPermitDialog = ({
  formStructure,
  dialogOpen,
  onDialogClose,
  onSubmit,
  onSuccess,
  onError,
}) => {
  const handleSubmit = async (data) => {
    try {
      const formattedBody = formatPatchSubmition(data);
      await onSubmit(formattedBody);
      onDialogClose();
      onSuccess();
    } catch (error) {
      onError(error);
    }
  };

  return (
    <Dialog
      fullWidth
      data-cy="permit-number-dialog"
      open={dialogOpen}
      onClose={onDialogClose}
    >
      <DialogTitle>Edit Additional Details</DialogTitle>
      <DialogContent>
        <LoadablePromise action={handleSubmit}>
          {(action) => (
            <FormBuilder
              final
              structure={formStructure}
              onSubmit={action}
              submitButtonText="Save"
              onCancel={onDialogClose}
            />
          )}
        </LoadablePromise>
      </DialogContent>
    </Dialog>
  );
};

export default NoonlightPermitDialog;
