import React, {Fragment, useEffect, useState, useRef} from 'react';
import {
  Box,
  Menu,
  TextField,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import {isEmpty} from 'lodash';

import PropTypes from 'prop-types';
import IconButtonWithTooltip from '../iconButtonWithTooltip';
import FilterField from '../filterField';
import LoadingScreen from '../loadingScreen';
import SitesCategoriesList from '../../../apps/sitesCategoriesList';
import {findCategory} from '../../util/sites';
import Spinner from '../spinner';

const SiteSelectionMenu = (props) => {
  const {
    title,
    siteSearchList,
    setCurrentSite,
    dropdownSiteNav,
    categoriesOnly,
    disabled,
    anchor,
    dropdownProps,
    autofocus,
    label,
  } = props;
  const [anchorEl, setAnchorEl] = useState(anchor || null);
  const [filterValue, setFilterValue] = useState();
  const [isFiltering, setIsFiltering] = useState(false);
  const [filteredSites, setFilteredSites] = useState(null);
  const [categoryId, setCategoryId] = useState(
    props.site ? props.site.categoryId : props.categoryId,
  );

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const menuAnchorRef = useRef();
  const inputRef = useRef(null);

  useEffect(
    () => {
      if (autofocus && !isSmallScreen) {
        setAnchorEl(menuAnchorRef.current);
        if (inputRef.current) {
          inputRef.current.focus();
        }
      } else {
        setAnchorEl(null);
      }
    },
    [autofocus, isSmallScreen],
  );

  const handleClick = (event) => {
    setCategoryId(props.rootCategory.id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleFilterChange = (filteredData, filterVal) => {
    if (!filterVal) {
      setFilterValue('');
      setIsFiltering(false);
      setFilteredSites(null);
    } else {
      setFilteredSites((rootCategory) => ({
        ...rootCategory,
        children: filteredData,
      }));
      setFilterValue(filterVal);
      setIsFiltering(false);
    }
  };

  const root = filteredSites || findCategory(props.rootCategory, categoryId);

  const handleCategoryClick = (siteOrCategory) => {
    if (siteOrCategory.type === 'category') {
      setFilterValue('');
      setIsFiltering(false);
      setFilteredSites(null);
      setCategoryId(siteOrCategory.id);
      if (dropdownSiteNav) {
        setCurrentSite({category: siteOrCategory});
      }
      return;
    }
    setCurrentSite({currentSite: siteOrCategory.id, name: siteOrCategory.name});
    handleClose();
  };
  let sitesCategoriesList = filteredSites
    ? filteredSites.children
    : root.children;
  if (categoriesOnly) {
    sitesCategoriesList = sitesCategoriesList.filter(
      (siteOrCategory) => siteOrCategory.type === 'category',
    );
  }

  const renderFilterAndSitesList = () => (
    <Fragment>
      <FilterField
        size="small"
        variant="outlined"
        label={categoriesOnly ? 'Search Folders' : 'Search Sites'}
        sx={() => ({
          float: 'right',
          zIndex: 1,
          width: '100%',
        })}
        dataSet={siteSearchList}
        onFilter={(val) => {
          setFilterValue(val);
          setIsFiltering(true);
        }}
        onDataSetUpdate={handleFilterChange}
        filterValue={filterValue}
        fields={['name', 'siteAddress', 'applianceIds']}
        debounce
        autofocus={autofocus && !isSmallScreen}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      />
      {isFiltering && siteSearchList.length > 500 ? (
        <LoadingScreen />
      ) : (
        <div id="siteNavDropdown">
          <SitesCategoriesList
            dropdownNav
            dropdownSiteNav={dropdownSiteNav}
            categoriesOnly={categoriesOnly}
            list={sitesCategoriesList}
            hasFilter={!!filteredSites}
            onCategoryClick={(site) => handleCategoryClick(site)}
            disableSiteDetails
          />
        </div>
      )}
    </Fragment>
  );
  return (
    <Fragment>
      <TextField
        id="site-selection-menu"
        label={label}
        value={title || 'Select ...'}
        onClick={handleClick}
        ref={menuAnchorRef}
        name="drop-down-nav"
        disabled={disabled}
        InputProps={{
          endAdornment: <ArrowDropDown />,
        }}
        sx={{width: '100%'}}
        {...dropdownProps}
        inputRef={(element) => (inputRef.current = element)}
      >
        {`${title}`}
      </TextField>
      <Menu
        id="site-select-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          minWidth: isSmallScreen ? '350px' : '500px',
          maxWidth: isSmallScreen ? '400px' : '100%',
          width: '100%',
          height: '85%',
        }}
        slotProps={{
          paper: {
            sx: {
              overflowY: 'scroll',
              overflowX: 'hidden',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
            },
          },
        }}
      >
        <Paper
          sx={(theme) => ({
            minWidth: isSmallScreen ? '350px' : '500px',
            width: '100%',
            padding: theme.spacing(2),
          })}
          elevation={0}
          id="site-nav-dropdown"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <IconButtonWithTooltip
              sx={(theme) => ({
                paddingRight: theme.spacing(4),
              })}
              color="primary"
              title="back"
              name="folder-back-button"
              onClick={() => {
                setCategoryId(root.parentId);
                if (categoriesOnly) {
                  setCurrentSite({
                    category: findCategory(props.rootCategory, root.parentId),
                  });
                }
              }}
              disabled={
                filterValue ||
                root.parentId === '-1' ||
                (filterValue === '' && root.parentId === undefined)
              }
            >
              <ArrowBackOutlinedIcon />
            </IconButtonWithTooltip>

            <Typography data-cy="root-name" variant="h6">
              {root.name}
            </Typography>
          </Box>
          {isEmpty(siteSearchList) ? (
            <Spinner color="primary" size={40} />
          ) : (
            renderFilterAndSitesList()
          )}
        </Paper>
      </Menu>
    </Fragment>
  );
};
SiteSelectionMenu.propTypes = {
  title: PropTypes.string,
  siteSearchList: PropTypes.arrayOf(PropTypes.shape({})),
  rootCategory: PropTypes.shape({}),
  setRouteState: PropTypes.func,
  setCurrentSite: PropTypes.func,
  dropdownSiteNav: PropTypes.bool,
  categoriesOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  anchor: PropTypes.string,
  dropdownProps: PropTypes.shape({}),
  autofocus: PropTypes.bool,
  label: PropTypes.string,
};

SiteSelectionMenu.defaultProps = {
  title: '',
  siteSearchList: [],
  rootCategory: {},
  setRouteState: () => {},
  setCurrentSite: () => {},
  dropdownSiteNav: true,
  categoriesOnly: false,
  disabled: false,
  anchor: '',
  dropdownProps: undefined,
  autofocus: false,
  label: 'Site',
};

export default SiteSelectionMenu;
