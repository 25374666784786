import React from 'react';
import PropTypes from 'prop-types';
import {Alert, AlertTitle} from '@mui/material';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import AlarmConfigurationAccordion from './AlarmConfigurationAccordion';
import AlarmCurrentMode from './AlarmCurrentMode';
import Spinner from '../../../../shared/components/spinner';
import AlarmModeContent from './AlarmModeContent';

const AlarmModeSection = ({
  selectedSite,
  snackbar,
  onNotificationsConfigurationOpen,
  onSchedulesConfigurationOpen,
  onCamerasConfigurationOpen,
  onAlarmModeUpdate,
  alarmModeState,
  onAlarmModeChange: updateAlarmStatus,
}) => {
  const isLoading = alarmModeState.state === 'LOADING';
  const isError = alarmModeState.state === 'ERROR';

  if (isError) {
    return (
      <Alert severity="error">
        <AlertTitle>Alarm Modes</AlertTitle>
        Failed to fetch the content
      </Alert>
    );
  }

  return (
    selectedSite && (
      <AlarmConfigurationAccordion
        key={selectedSite.id}
        headerTitle="Alarm Modes"
        headerIcon={<HeadphonesIcon />}
        summaryContent={
          isLoading ? (
            <Spinner size={40} color="primary" />
          ) : (
            alarmModeState.siteStatus && (
              <AlarmCurrentMode
                alarmStatuses={alarmModeState.siteStatus}
                alarmsCount={selectedSite.alarmsCount}
              />
            )
          )
        }
        detailedContent={
          alarmModeState.siteStatus &&
          !isLoading && (
            <AlarmModeContent
              snackbar={snackbar}
              alarmModeState={alarmModeState}
              onCamerasConfigurationOpen={onCamerasConfigurationOpen}
              onSchedulesConfigurationOpen={onSchedulesConfigurationOpen}
              onNotificationsConfigurationOpen={
                onNotificationsConfigurationOpen
              }
              onAlarmModeUpdate={onAlarmModeUpdate}
              updateAlarmStatus={updateAlarmStatus}
            />
          )
        }
      />
    )
  );
};

AlarmModeSection.propTypes = {
  selectedSite: PropTypes.shape({}).isRequired,
  snackbar: PropTypes.shape({}).isRequired,
  onCamerasConfigurationOpen: PropTypes.func.isRequired,
  onSchedulesConfigurationOpen: PropTypes.func.isRequired,
  onNotificationsConfigurationOpen: PropTypes.func.isRequired,
  alarmModeState: PropTypes.shape({}).isRequired,
  updateAlarmStatus: PropTypes.func,
  onAlarmModeUpdate: PropTypes.func,
};

AlarmModeSection.defaultProps = {
  onAlarmModeUpdate: () => {},
  updateAlarmStatus: () => {},
};

export default AlarmModeSection;
