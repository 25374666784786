import React from 'react';
import {useLocation, Redirect} from 'react-router-dom';

import {isNewNavigationWebEnabled} from '../../../shared/util/user';
import {withCurrentUser} from '../../../shared/contexts/currentUserContext';

const SitesSettingsNewNavRedirect = ({currentUser}) => {
  const location = useLocation();
  const {pathname, search} = location;

  if (isNewNavigationWebEnabled(currentUser)) {
    const siteSettingsRegex = /^\/(?:ui\/home|ui|home)?\/?sites\/([^/]+)\/settings\/([^/]+)$/;

    const siteSettingsMatch = pathname.match(siteSettingsRegex);

    if (siteSettingsMatch) {
      const [, siteId, settingsPath] = siteSettingsMatch;

      const newPath = `/home/site-settings/${settingsPath}`;
      const isSiteIdQueryParamIncluded = search.includes('siteId');
      const searchWitchSiteId = isSiteIdQueryParamIncluded
        ? search
        : `${search}&siteId=${siteId}`;

      return (
        <Redirect
          to={{
            pathname: newPath,
            search: searchWitchSiteId,
          }}
        />
      );
    }
  }

  return null;
};

export default withCurrentUser(SitesSettingsNewNavRedirect);
