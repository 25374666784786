import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useReducer,
} from 'react';
import {Grid, Box, Typography, Divider} from '@mui/material';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import CollapsibleTable from '../../../../shared/components/collapsibleTable';
import SelectList from '../../../../shared/components/selectList';
import CallbackFilterField from '../../../../shared/components/callbackFilterField';
import {getSafetyButtonsSummaryForSites} from '../../../../api/alarms';
import ButtonModeChip from './ButtonModeChip';
import AlarmDrawer from '../AlarmDrawer';
import {isNewNavigationWebEnabled} from '../../../../shared/util/user';
import SafetyButtonSection from './SafetyButtonSection';
import {getSiteNotificationSubscriptions} from '../../../../api/notifications';
import {subscriptionEventTypes} from '../notifications/utils';
import {getCamerasBySiteId} from '../../../../api/cameras';
import {AlarmSiteConfigurationContextProvider} from '../AlarmSiteConfigurationContext';
import SafetyButtonNotifications from './SafetyButtonNotifications';
import {handleFilterChange, filterReducer} from '../filterReducer';

const columnDefinitions = [
  {
    id: 'siteName',
    label: 'Site Name',
  },
  {
    id: 'mode',
    label: 'Button Modes',
    notSortable: true,
  },
];

const initialOrderOptions = {
  initialOrderBy: 'siteName',
  initialOrder: 'asc',
};

const modeToLabel = (mode) => startCase(lowerCase(mode));

const buttonModes = ['active', 'eventsOnly', 'test', 'inactive'];
const ButtonsModes = ({buttons}) => {
  const totalButtons = buttonModes.reduce(
    (total, mode) => total + (buttons[mode] || 0),
    0,
  );

  if (buttons.active === totalButtons) {
    return <ButtonModeChip mode="active" customLabel="All Active" />;
  }

  return (
    <Box sx={{display: 'flex', gap: 1}}>
      {buttonModes
        .filter((mode) => buttons[mode])
        .map((mode) => (
          <ButtonModeChip
            key={mode}
            mode={mode}
            customLabel={`${buttons[mode]} ${modeToLabel(mode)}`}
          />
        ))}
    </Box>
  );
};

ButtonsModes.propTypes = {
  buttons: PropTypes.shape({
    active: PropTypes.number,
    eventsOnly: PropTypes.number,
    test: PropTypes.number,
    inactive: PropTypes.number,
  }).isRequired,
};

const ConfigurationSection = ({currentUser, snackbar}) => {
  const isNewNavigation = isNewNavigationWebEnabled(currentUser);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [siteSubscriptions, setSiteSubscriptions] = useState([]);
  const [siteCameras, setSiteCameras] = useState([]);
  const [reloadDataTrigger, setReloadDataTrigger] = useState(false); // State variable to trigger site data reload

  useEffect(() => {
    const getSubscriptions = async () => {
      setSiteSubscriptions(await getSiteNotificationSubscriptions());
    };
    getSubscriptions();
  }, []);

  useEffect(
    () => {
      if (selectedSite) {
        getCamerasBySiteId(selectedSite.siteId).then(setSiteCameras);
      }
    },
    [selectedSite],
  );

  const [{isSearchValueValid, searchValue}, dispatch] = useReducer(
    filterReducer,
    {
      isSearchValueValid: true,
      searchValue: '',
    },
  );

  const [buttonModesFilter, setButtonModesFilter] = useState([]);
  const onLoadData = useCallback(
    async (page, limit, sortingOrder) => {
      const {count, results: sites} = await getSafetyButtonsSummaryForSites(
        page * limit,
        limit,
        sortingOrder,
        buttonModesFilter.length ? buttonModesFilter.join() : undefined,
        searchValue.length ? searchValue : undefined,
      );

      return {
        count,
        results: sites.map((result) => ({
          ...result,
          mode: <ButtonsModes buttons={result.buttons} />,
        })),
      };
    },
    [buttonModesFilter, searchValue],
  );

  const hasPanicButtonSubscription =
    selectedSite &&
    siteSubscriptions.some(
      ({siteId, type, eventType}) =>
        siteId === selectedSite.siteId &&
        type === 'noonlight' &&
        eventType === subscriptionEventTypes.panic,
    );

  const onRowClick = (siteData) => {
    setSelectedSite((prev) => {
      // guard for same site click
      if (prev?.siteId !== siteData.siteId) {
        setSiteCameras(null);
      }
      setDrawerOpened(true);
      return siteData;
    });
  };

  return (
    <Fragment>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="flex-start"
        spacing={{xs: 0.5, sm: 2, md: 2, lg: 2, xl: 3}}
      >
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <SelectList
            id="button-mode-filter"
            label="Button Mode"
            options={buttonModes.map((m) => ({id: m, name: modeToLabel(m)}))}
            onChange={(selectedValues) =>
              setButtonModesFilter(selectedValues.map(({id}) => snakeCase(id)))
            }
            isMulti
            clearable
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CallbackFilterField
            sx={{width: '100%', margin: 0}}
            data-cy="site-panic-search"
            isDebounced
            filterValue={searchValue}
            onFilter={(value) => handleFilterChange(dispatch, value)}
            error={!isSearchValueValid}
            helperText={
              isSearchValueValid ? null : 'Please enter at least 3 characters'
            }
          />
        </Grid>
        <Grid data-cy="sites-table" item xs={12} display="grid">
          <CollapsibleTable
            rowId="siteId"
            columns={columnDefinitions}
            sortable
            orderOptions={initialOrderOptions}
            onLoadData={onLoadData}
            reloadData={reloadDataTrigger}
            onRowClick={onRowClick}
            paginateOptions={{initialRecords: 10, useBasicNavigation: true}}
            stickyHeader={false}
            selectedId={selectedSite?.siteId}
          />
        </Grid>
      </Grid>

      <AlarmDrawer
        onClose={() => {
          setDrawerOpened(false);
        }}
        open={drawerOpened}
        newNavigationEnabled={isNewNavigation}
      >
        {selectedSite ? (
          <AlarmSiteConfigurationContextProvider>
            <Box>
              <Typography
                name="site-name"
                sx={{paddingLeft: 2, paddingTop: 2}}
                variant="h6"
                gutterBottom
              >
                {selectedSite.siteName}
              </Typography>
            </Box>

            {hasPanicButtonSubscription && siteCameras?.length > 0 && (
              <Box name="safety-button" sx={{pt: 1, pb: 3, px: 4}}>
                <SafetyButtonSection
                  selectedSiteId={selectedSite.siteId}
                  selectedSiteName={selectedSite.siteName}
                  cameras={siteCameras}
                  snackbar={snackbar}
                  onSafetyButtonsChange={
                    () => setReloadDataTrigger((prev) => !prev) // Toggle the reload site data trigger
                  }
                />
              </Box>
            )}

            {hasPanicButtonSubscription && (
              <Fragment>
                <Divider variant="middle" />
                <Box sx={{pt: 1, pb: 3, px: 4}}>
                  <SafetyButtonNotifications
                    site={{
                      id: selectedSite.siteId,
                      name: selectedSite.siteName,
                    }}
                  />
                </Box>
              </Fragment>
            )}
          </AlarmSiteConfigurationContextProvider>
        ) : (
          <Fragment />
        )}
      </AlarmDrawer>
    </Fragment>
  );
};

ConfigurationSection.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  snackbar: PropTypes.shape({}).isRequired,
};

export default ConfigurationSection;
