import React, {useContext, useEffect, useState} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {hideGlobalMenu} from '../../util/general';
import {getFullUserById} from '../../../api/users';
import OldNav from '../../../apps/oldNav';
import TopNavigationBar from './topNavigationBar';
import {responsiveTheme} from '../../themes';
import {isNewNavigationWebEnabled} from '../../util/user';
import {TopNavigationContext} from './topNavigationContext';

const NavigationBar = () => {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const {title, backLink} = useContext(TopNavigationContext);

  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      try {
        setUser(await getFullUserById());
      } catch (e) {
        if (e.response && e.response.status !== 401) throw e;
      } finally {
        setIsLoading(false);
      }
    };
    if (!hideGlobalMenu()) {
      getUserData();
    }
  }, []);

  return (
    !hideGlobalMenu() &&
    !isLoading &&
    (isNewNavigationWebEnabled(user) ? (
      <ThemeProvider theme={responsiveTheme}>
        <TopNavigationBar user={user} title={title} backLink={backLink} />
      </ThemeProvider>
    ) : (
      <OldNav />
    ))
  );
};

export default NavigationBar;
