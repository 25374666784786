import {useCallback, useEffect, useState, useRef} from 'react';
import {
  useListPaginator,
  useApi,
  paginationStatus,
} from '../../../../shared/hooks';
import {
  getPaginatedUsers,
  getSiteNotificationSubscription,
  getSubscribers,
} from '../../../../api/subscribers';

import {getDmpConfig} from '../../../../api/dmp';

export const notificationSubscriptionStatuses = Object.freeze({
  Initial: 'Initial',
  Loading: 'Loading',
  Reloading: 'Reloading',
  Fetched: 'Fetched',
  Success: 'Success',
  Terminated: 'Terminated',
  Error: 'Error',
});

export const useNotificationSubscriptions = (siteId, eventType) => {
  const [status, setStatus] = useState(
    notificationSubscriptionStatuses.Initial,
  );
  const [standardUsers, setStandardUsers] = useState();
  const [priorityUsers, setPriorityUsers] = useState();
  const searchValue = useRef();

  const [
    usersDataStatus,
    usersData,
    onFetchMoreData,
    totalUsersCount,
  ] = useListPaginator((limit, offset) =>
    getPaginatedUsers(siteId, limit, offset, searchValue.current),
  );

  const [subscribers, , , fetchSubscribers] = useApi(getSubscribers);
  const [siteSubscriptions, , , fetchSiteSubscriptions] = useApi(
    getSiteNotificationSubscription,
  );

  const onSearchUsers = useCallback(
    async (query) => {
      searchValue.current = query;
      setStatus(notificationSubscriptionStatuses.Reloading);
      setStandardUsers(null);
      await onFetchMoreData(true);
      setStatus(notificationSubscriptionStatuses.Fetched);
    },
    [onFetchMoreData],
  );

  const onGetMoreUsers = useCallback(
    async (resetPagination) => {
      if (resetPagination) {
        setStatus(notificationSubscriptionStatuses.Loading);
        setStandardUsers(null);
        setPriorityUsers(null);
      } else {
        setStatus(notificationSubscriptionStatuses.Reloading);
      }

      await Promise.all([
        onFetchMoreData(resetPagination),
        fetchSiteSubscriptions(siteId, eventType),
        fetchSubscribers(siteId, eventType),
      ]);
      setStatus(notificationSubscriptionStatuses.Fetched);
    },
    [
      eventType,
      fetchSiteSubscriptions,
      fetchSubscribers,
      onFetchMoreData,
      siteId,
    ],
  );

  useEffect(
    () => {
      if (status === notificationSubscriptionStatuses.Fetched) {
        if (
          subscribers === undefined ||
          usersData === undefined ||
          siteSubscriptions === undefined
        ) {
          setStatus(notificationSubscriptionStatuses.Error);
          return;
        }

        const priority = subscribers.filter((user) =>
          user.subscriptions.some((sub) => sub.type === 'noonlight'),
        );

        const standard = usersData
          .map((usr) => {
            const sbrData = subscribers.find((sbr) => sbr.uuid === usr.id);

            if (sbrData) {
              const {subscriptions} = sbrData;

              return {
                ...usr,
                subscriptions,
              };
            }
            return usr;
          })
          .filter((usr) => usr);

        setPriorityUsers(priority);
        setStandardUsers(standard);
        if (usersDataStatus === paginationStatus.Terminated) {
          setStatus(notificationSubscriptionStatuses.Terminated);
        } else {
          setStatus(notificationSubscriptionStatuses.Success);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status],
  );

  return [
    status,
    standardUsers,
    priorityUsers,
    totalUsersCount,
    siteSubscriptions,
    onGetMoreUsers,
    onSearchUsers,
    searchValue,
  ];
};

export const useSspNotifications = (siteId, notificationsDialogOpen) => {
  const [expanded, setExpanded] = useState(false);
  const [dmpConfigResponse, dmpConfigLoading, , getDmpConfigApi] = useApi(
    getDmpConfig,
  );
  const [subscribersResponse, subscribersLoading, , getSubscribersApi] = useApi(
    getSubscribers,
  );
  useEffect(
    () => {
      async function fetchData() {
        setExpanded(false);
        getSubscribersApi(siteId);
        getDmpConfigApi(siteId);
      }

      if (siteId && notificationsDialogOpen === false) fetchData();
    },
    [siteId, notificationsDialogOpen, getDmpConfigApi, getSubscribersApi],
  );

  return {
    loading: dmpConfigLoading || subscribersLoading,
    subscribers: subscribersResponse ?? [],
    hasDmpPanel: !!dmpConfigResponse?.integrationId,
    expanded,
    setExpanded,
  };
};
