import React from 'react';
import SpeedIcon from '@mui/icons-material/Speed';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AttributionOutlinedIcon from '@mui/icons-material/AttributionOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';

import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import {hasRequiredCapabilityWithMatchingPreference} from '../../../shared/components/navigationBar/utils';
import DevicesNewNavigation from '../settings/devicesNewNavigation';
import SitePerformanceNewNavigation from '../settings/sitePerformanceNewNavigation';
import ConfigurationNewNavigation from '../settings/configurationNewNavigation';

import {
  ENVR_ADMIN,
  INTEGRATION_ADMIN,
  CLOUD_ARCHIVE_ADMIN,
  CLOUD_ARCHIVE_USER,
  ENVY_LOCAL_ACCESS,
  GRANT_TEMP_ACCESS,
} from '../../../shared/util/allowed';
import EnvysionLocalNewNavigation from '../settings/envysionLocalNewNavigation';
import IntegrationsNewNavigation from '../settings/integrationsNewNavigation';
import SiteAccessNewNavigation from '../settings/siteAccessNewNavigation';

const devices = (props) => <DevicesNewNavigation {...props} />;
const sitePerformance = (props) => <SitePerformanceNewNavigation {...props} />;
const envysionLocal = (props) => <EnvysionLocalNewNavigation {...props} />;
const configuration = (props) => <ConfigurationNewNavigation {...props} />;
const integrations = (props) => <IntegrationsNewNavigation {...props} />;
const siteAccess = (props) => <SiteAccessNewNavigation {...props} />;

export const getTabs = (currentUser) =>
  [
    {
      key: 'devices',
      path: 'devices',
      primaryTitleText: 'Devices',
      component: devices,
      icon: <SettingsInputSvideoIcon />,
      capabilities: [ENVR_ADMIN],
      preferences: [],
      displayOnSmallScreen: true,
    },
    {
      key: 'site-performance',
      path: 'site-performance',
      primaryTitleText: 'Site Performance',
      component: sitePerformance,
      icon: <SpeedIcon />,
      capabilities: [ENVR_ADMIN, CLOUD_ARCHIVE_ADMIN, CLOUD_ARCHIVE_USER],
      preferences: [],
      displayOnSmallScreen: true,
    },
    {
      key: 'envysion-local',
      path: 'envysion-local',
      primaryTitleText: 'Envysion Local',
      component: envysionLocal,
      icon: <ManageAccountsOutlinedIcon />,
      capabilities: [ENVY_LOCAL_ACCESS],
      preferences: [],
      displayOnSmallScreen: true,
    },
    {
      key: 'integrations',
      path: 'integrations',
      primaryTitleText: 'Integrations',
      component: integrations,
      icon: <IntegrationInstructionsOutlinedIcon />,
      capabilities: [INTEGRATION_ADMIN],
      preferences: [],
      displayOnSmallScreen: true,
    },
    {
      key: 'configuration',
      path: 'configuration',
      primaryTitleText: 'Configuration',
      component: configuration,
      icon: <TuneOutlinedIcon />,
      capabilities: [ENVR_ADMIN],
      preferences: [{enableDmpConfiguration: true}],
      displayOnSmallScreen: true,
    },
    {
      key: 'site-access',
      path: 'site-access',
      primaryTitleText: 'Temporary Access',
      component: siteAccess,
      icon: <AttributionOutlinedIcon />,
      capabilities: [GRANT_TEMP_ACCESS],
      preferences: [],
      displayOnSmallScreen: true,
    },
  ].filter((item) =>
    hasRequiredCapabilityWithMatchingPreference(item, currentUser),
  );
