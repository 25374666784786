import React from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/flowRight';
import groupBy from 'lodash/groupBy';
import merge from 'lodash/merge';
import zipObject from 'lodash/zipObject';

import PageLoader from '../../../../shared/components/pageLoader';
import withSnackbar from '../../../../shared/components/snackbarSupport';
import {getApplianceStatuses} from '../../../../shared/util/appliances';
import {withLogger} from '../../../../shared/components/logger';
import {
  getSiteCloudArchiveSettings,
  getSiteCloudArchiveHealth,
} from '../../../../api/cloudArchive';
import {getCamerasBySiteId, getResolutions} from '../../../../api/cameras';
import {
  getAudioChannelsByApplianceId,
  getAppliancesForSite,
  getAppliance,
  getAppliancePreferences,
  getNetworkDetailsFromAppliance,
  getNetworkConfigFromDatabase,
} from '../../../../api/appliances';
import {getTimezones} from '../../../../api/sites';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import allowed, {
  ENVR_ADMIN,
  MOTION_ALARM_ADMIN,
  ACCESS_CONTROL_ADMIN,
} from '../../../../shared/util/allowed';
import {getRegisters} from '../../../../api/registers';
import {getDmpConfig} from '../../../../api/dmp';
import {getDeterrents} from '../../../../api/deterrents';
import {getEntries, getEntriesPerCamera} from '../../../../api/accessControl';
import SiteSettingsWrapperNewNavigation from '../utils/SiteSettingsWrapperNewNavigation';

const DevicesNewNavigation = ({snackbar, currentUser, logger}) => {
  const getProfile = (cam) => {
    if (!cam.enabled) {
      return 'disabled';
    }
    if (cam.isUnmanaged) {
      return 'record_only';
    }
    return 'managed';
  };

  const isProbeable = (camera) => camera.isIp && camera.isOnline;

  return (
    <SiteSettingsWrapperNewNavigation name="devices-page-content">
      {(selectedSite) => {
        return (
          <PageLoader
            page={() => import('../devices')}
            resources={{
              camerasAppliancesAndAudioChannels: async () => {
                const siteCameras = await getCamerasBySiteId(selectedSite.id);
                const camerasByAppliance = groupBy(siteCameras, 'applianceId');

                const getAppliances = async () => {
                  const siteAppliances = await getAppliancesForSite(
                    selectedSite.id,
                  );

                  return Promise.all(
                    siteAppliances.map(async (a) => ({
                      ...(await getAppliance(a.id)),

                      cameraDiscovery: a.cameraDiscovery,
                    })),
                  );
                };
                const fetchedAppliances = await getAppliances();
                const activeAppliances = fetchedAppliances.filter(
                  (appliance) => appliance.active === true,
                );
                const appliancesById = fetchedAppliances.reduce(
                  (acc, appliance) => {
                    acc[appliance.id] = appliance;
                    return acc;
                  },
                  {},
                );

                const getAllAppliancePreferences = await Promise.all(
                  fetchedAppliances.map(async (a) => {
                    const preferences = await getAppliancePreferences(a.id);
                    return preferences.reduce((acc, p) => {
                      if (p.name === 'maxRetentionHours') {
                        // eslint-disable-next-line no-param-reassign
                        a.maxRetentionHours = p.value;
                      }
                      if (p.visible) {
                        acc.push(p);
                      }
                      return acc;
                    }, []);
                  }),
                );

                const appliancePreferencesByApplianceId = zipObject(
                  fetchedAppliances.map((appliance) => appliance.id),
                  getAllAppliancePreferences,
                );

                const getNetworkConfigsAndDetails = async () => {
                  try {
                    const applianceNetworkConfigsByApplianceId = {};
                    const applianceNetworkDetailsByApplianceId = {};
                    await Promise.all(
                      fetchedAppliances.map(async (appliance) => {
                        const [config, details] = await Promise.all([
                          getNetworkConfigFromDatabase(appliance.id),
                          getNetworkDetailsFromAppliance(appliance.id).catch(
                            (error) => {
                              logger.error(
                                'Failed to fetch network details',
                                {},
                                error,
                              );
                              return {};
                            },
                          ),
                        ]);

                        const initNetworkConfig = (network) => {
                          if (!config[network] && details[network]) {
                            config[network] = {
                              ipv4Address: details[network]?.[0].address,
                              ipv4Netmask: details[network]?.[0].netmask,
                            };
                          }
                        };

                        initNetworkConfig('eth0:1');
                        initNetworkConfig('eth1');

                        applianceNetworkConfigsByApplianceId[
                          appliance.id
                        ] = config;

                        applianceNetworkDetailsByApplianceId[appliance.id] = {
                          'eth0:1': {
                            present: details['eth0:1']?.[0]?.present,
                            connected: details['eth0:1']?.[0]?.connected,
                          },
                          eth1: {
                            present: details.eth1?.[0]?.present,
                            connected: details.eth1?.[0]?.connected,
                          },
                        };
                        return config;
                      }),
                    );
                    return {
                      applianceNetworkConfigsByApplianceId,
                      applianceNetworkDetailsByApplianceId,
                    };
                  } catch (error) {
                    logger.error('Failed to fetch network configs', {}, error);
                    throw new Error('Failed to fetch network configs');
                  }
                };

                const {
                  applianceNetworkConfigsByApplianceId,
                  applianceNetworkDetailsByApplianceId,
                } = await getNetworkConfigsAndDetails();

                const primaryNetworkConfigsByApplianceId = fetchedAppliances.reduce(
                  (acc, appliance) => {
                    acc[appliance.id] =
                      appliancesById[appliance.id]?.network?.eth0;
                    return acc;
                  },
                  {},
                );

                const audioChannels = await Promise.all(
                  fetchedAppliances.map(async ({id}) =>
                    getAudioChannelsByApplianceId(id),
                  ),
                );
                const audioChannelsByApplianceIdAndAudioChannel = audioChannels
                  .flat()
                  .reduce((acc, audioChannel) => {
                    if (!acc[audioChannel.applianceId]) {
                      acc[audioChannel.applianceId] = {};
                    }
                    acc[audioChannel.applianceId][
                      audioChannel.channel
                    ] = audioChannel;
                    return acc;
                  }, {});

                const probeResponsesByAppliance = await Promise.all(
                  Object.entries(camerasByAppliance).map(
                    async ([, cameras]) => {
                      const probeableCameras = cameras.filter((camera) =>
                        isProbeable(camera),
                      );

                      const probedCameras = probeableCameras.map((camera) => ({
                        ip_address: camera.ipAddress,
                        manageable: true,
                      }));
                      return probedCameras.reduce(
                        (acc, probe, idx) => ({
                          ...acc,
                          [probeableCameras[idx].id]: probe,
                        }),
                        {},
                      );
                    },
                  ),
                );

                const probesByCameraId = merge(...probeResponsesByAppliance);
                return {
                  cameras: siteCameras.map((cam) => {
                    const cameraTiedToAudioChannel =
                      audioChannelsByApplianceIdAndAudioChannel[
                        cam.applianceId
                      ] &&
                      audioChannelsByApplianceIdAndAudioChannel[
                        cam.applianceId
                      ][cam.audioRecordChannel];
                    const {dvrMakeName} = appliancesById[cam.applianceId];
                    const getAudioGain = () => {
                      if (dvrMakeName === 'Envysion') {
                        return cameraTiedToAudioChannel
                          ? audioChannelsByApplianceIdAndAudioChannel[
                              cam.applianceId
                            ][cam.audioRecordChannel].gain
                          : null;
                      }
                      return (
                        audioChannelsByApplianceIdAndAudioChannel[
                          cam.applianceId
                        ]?.[cam.channel]?.gain ?? null
                      );
                    };

                    return {
                      ...cam,
                      audioRecordChannelId: cameraTiedToAudioChannel
                        ? audioChannelsByApplianceIdAndAudioChannel[
                            cam.applianceId
                          ][cam.audioRecordChannel].id
                        : null,
                      recordAudio: cameraTiedToAudioChannel
                        ? !!audioChannelsByApplianceIdAndAudioChannel[
                            cam.applianceId
                          ][cam.audioRecordChannel].recordEnabled
                        : false,
                      audioGain: getAudioGain(),
                      img: `/api/v3/sensor_views/${cam.id}/thumbnail`,
                      profile: getProfile(cam),
                      isProbeable: isProbeable(cam),
                      probe: probesByCameraId[cam.id] || {},
                    };
                  }),
                  audioChannels: audioChannels.flat().map((audioChannel) => ({
                    ...audioChannel,
                    recordEnabled: !!audioChannel.recordEnabled,
                  })),
                  appliances: fetchedAppliances,
                  activeAppliances,
                  applianceStatuses: await getApplianceStatuses(
                    activeAppliances,
                  ),
                  appliancePreferences: appliancePreferencesByApplianceId,
                  primaryNetworkConfigs: primaryNetworkConfigsByApplianceId,
                  applianceNetworkConfigs: applianceNetworkConfigsByApplianceId,
                  applianceNetworkDetails: applianceNetworkDetailsByApplianceId,
                };
              },
              registers: getRegisters,
              resolutions: getResolutions,
              timezones: getTimezones,
              cloudArchiveSettings: () =>
                getSiteCloudArchiveSettings(selectedSite.id),
              cloudArchiveHealth: async () => {
                let cloudArchiveHealthStatus = {};
                try {
                  cloudArchiveHealthStatus = await getSiteCloudArchiveHealth(
                    selectedSite.id,
                  );
                } catch (error) {
                  logger.error(
                    'Failed to fetch cloud archive health',
                    {},
                    error,
                  );
                }
                return cloudArchiveHealthStatus;
              },
              entries: () =>
                allowed(currentUser, [ACCESS_CONTROL_ADMIN])
                  ? getEntries(selectedSite.id)
                  : Promise.resolve([]),
              entriesPerCamera: () =>
                allowed(currentUser, [ACCESS_CONTROL_ADMIN])
                  ? getEntriesPerCamera(selectedSite.id)
                  : Promise.resolve([]),
              dmpConfig: () =>
                allowed(currentUser, [MOTION_ALARM_ADMIN])
                  ? getDmpConfig(selectedSite.id)
                  : Promise.resolve(null),
              // adapt the deterrent object body to use .id instead of .uuid, as the entire logic in device management is based on using .id prop name
              deterrents: () =>
                allowed(currentUser, [ENVR_ADMIN])
                  ? getDeterrents(selectedSite.id).then((data) =>
                      data.results.map(({uuid, ...rest}) => ({
                        id: uuid,
                        ...rest,
                      })),
                    )
                  : Promise.resolve([]),
            }}
            propsToIgnoreUpdate={['location', 'snackbar']}
            snackbar={snackbar}
            siteId={selectedSite.id}
            enableEntryConfiguration={allowed(currentUser, [
              ACCESS_CONTROL_ADMIN,
            ])}
            loadedToPageProps={({
              camerasAppliancesAndAudioChannels,
              registers,
              resolutions,
              timezones,
              entries,
              entriesPerCamera,
              dmpConfig,
              cloudArchiveSettings,
              cloudArchiveHealth,
              deterrents,
            }) => {
              return {
                cameras: camerasAppliancesAndAudioChannels.cameras,
                audioChannels: camerasAppliancesAndAudioChannels.audioChannels,
                registers,
                resolutions,
                timezones,
                appliances: camerasAppliancesAndAudioChannels.appliances,
                activeAppliances:
                  camerasAppliancesAndAudioChannels.activeAppliances,
                applianceStatuses:
                  camerasAppliancesAndAudioChannels.applianceStatuses,
                appliancePreferences:
                  camerasAppliancesAndAudioChannels.appliancePreferences,
                primaryNetworkConfigs:
                  camerasAppliancesAndAudioChannels.primaryNetworkConfigs,
                applianceNetworkConfigs:
                  camerasAppliancesAndAudioChannels.applianceNetworkConfigs,
                entries,
                entriesPerCamera,
                dmpConfig,
                applianceNetworkDetails:
                  camerasAppliancesAndAudioChannels.applianceNetworkDetails,
                cloudArchiveSettings,
                cloudArchiveHealth,
                deterrents,
              };
            }}
          />
        );
      }}
    </SiteSettingsWrapperNewNavigation>
  );
};

DevicesNewNavigation.propTypes = {
  snackbar: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  logger: PropTypes.shape({}).isRequired,
};

export default compose(
  withSnackbar,
  withCurrentUser,
  withLogger,
)(DevicesNewNavigation);
