import React, {useEffect} from 'react';
import {DateTime} from 'luxon';

import DvrIcon from '@mui/icons-material/Dvr';

import {useApi} from '../../../../shared/hooks';
import {isNewNavigationWebEnabled} from '../../../../shared/util/user';
import {getAppliancesHealthSummary} from '../../../../api/hardwareHealth';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {openInOneTab} from '../../utils';
import DashboardStatusWidget from '../../../../shared/components/dashboardComponent/dashboardStatusWidget';

const ApplianceWidget = ({widgetConfig, currentUser}) => {
  const [
    applianceHealthSummary,
    loading,
    isError,
    getApplianceHealthSummary,
  ] = useApi(getAppliancesHealthSummary);

  useEffect(
    () => {
      const currentTimestamp = DateTime.utc().toISO();
      const dayAgoTimestamp = DateTime.utc()
        .minus({
          hours: 24,
        })
        .toISO();
      getApplianceHealthSummary(dayAgoTimestamp, currentTimestamp);
    },
    [getApplianceHealthSummary],
  );

  const offlineChanges = applianceHealthSummary?.offlineChanges;
  const pathname = isNewNavigationWebEnabled(currentUser)
    ? '/home/system-health'
    : '/hardware-health';

  const content = {
    dualPanel: false,
    panels: [
      {
        icon: <DvrIcon />,
        title: 'Appliances',
        count: applianceHealthSummary?.offline,
        path: pathname,
        search: '?status=offline,impaired',
        state: {fromDashboard: true},
        target: openInOneTab(currentUser),
        buttonText: 'Offline',
        message: `${offlineChanges} Offline in the last 24 hrs.`,
      },
    ],
  };

  return (
    <DashboardStatusWidget
      id={widgetConfig.widgetReference}
      altContent={content}
      isError={isError}
      loading={loading}
      {...widgetConfig.properties}
    />
  );
};

export default withCurrentUser(ApplianceWidget);
