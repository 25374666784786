import {
  Menu,
  MenuItem,
  Link,
  ListItemIcon,
  Typography,
  Divider,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import {PropTypes} from 'prop-types';
import {hasRequiredCapabilityWithMatchingPreference} from './utils';

const NavigationMenu = (props) => {
  const {
    items,
    menuItem,
    anchorElMenu,
    handleCloseMenu,
    user,
    eventMenu,
  } = props;

  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <Menu
      sx={{mt: (theme) => theme.spacing(5)}}
      id="menu-appbar"
      anchorEl={anchorElMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={eventMenu === menuItem}
      onClose={handleCloseMenu}
    >
      {items.map((item) => {
        if (hasRequiredCapabilityWithMatchingPreference(item, user)) {
          return (
            <Link
              href={item.path}
              key={item.title}
              target={item.openNewTab ? '_blank' : ''}
              sx={{
                textDecoration: 'none',
              }}
              data-cy={`${item.title.replace(' ', '-').toLowerCase()}-link`}
            >
              <MenuItem
                key={item.title}
                onClick={handleCloseMenu}
                sx={{
                  padding: 1,
                  ':hover': {
                    backgroundColor: (theme) => theme.palette.primary.lightest,
                  },
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <Typography color="common.black">{item.title}</Typography>
              </MenuItem>
            </Link>
          );
        }
        return null;
      })}
      {!isMediumScreen && eventMenu === 'Profile' && (
        <div>
          <Divider />
          <Typography
            data-cy="menu-username-domain"
            sx={{p: 1, display: 'flex', justifyContent: 'center'}}
          >
            {user.displayName
              ? user.displayName
              : `${user.username}@${user.domainName}`}
          </Typography>
        </div>
      )}
    </Menu>
  );
};

NavigationMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      capabilities: PropTypes.arrayOf(PropTypes.string),
      preferences: PropTypes.arrayOf(PropTypes.shape({})),
      icon: PropTypes.node,
      title: PropTypes.string,
    }),
  ).isRequired,
  menuItem: PropTypes.string.isRequired,
  anchorElMenu: PropTypes.shape({}),
  handleCloseMenu: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    capabilities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    settings: PropTypes.shape({}),
  }).isRequired,
  eventMenu: PropTypes.string.isRequired,
};

NavigationMenu.defaultProps = {
  anchorElMenu: null,
};

export default NavigationMenu;
