import React, {Fragment, useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {DateTime, Duration} from 'luxon';
import capitalize from 'lodash/capitalize';
import snakeCase from 'lodash/snakeCase';

import {
  Box,
  Button,
  Dialog,
  List,
  styled,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridLogicOperator,
  gridClasses,
  getGridSingleSelectOperators,
  useGridApiRef,
  useGridRootProps,
} from '@mui/x-data-grid-pro';
import {LicenseInfo} from '@mui/x-license';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import PlaceIcon from '@mui/icons-material/Place';
import CircleIcon from '@mui/icons-material/Circle';

import allowed, {ENVR_ADMIN} from '../../shared/util/allowed';

import {
  getHardwareHealth,
  getHealthDetailsForSite,
} from '../../api/hardwareHealth';
import {getCamerasBySiteId} from '../../api/cameras';

import CameraTamperModal from '../../shared/components/cameraTamperModal';
import FilterPanel from './FilterPanel';
import EarliestVideoModal from './EarliestVideoModal';
import {isNewNavigationWebEnabled} from '../../shared/util/user';

const muiXKey = import.meta.env.VITE_MUIX_PRO;

if (muiXKey && muiXKey.length > 0) {
  LicenseInfo.setLicenseKey(muiXKey);
}

const formatDateTime = (time, zone) => {
  const eventTime = DateTime.fromISO(time).setZone(zone);
  const currentTime = DateTime.local().setZone(zone);
  if (!eventTime.isValid || eventTime > currentTime) {
    return null;
  }
  const msTimeDiff = currentTime.diff(eventTime);
  const daysDiff = Duration.fromMillis(msTimeDiff).toFormat('d');

  const absoluteDate = eventTime.toFormat('DDD');
  return [absoluteDate, `${daysDiff} Days`];
};

const formatText = (string) => {
  if (string === 'cameraStatus') {
    return 'offline_cameras';
  }
  return snakeCase(string);
};

const StatusIcon = styled(CircleIcon)(({theme}) => ({
  marginRight: theme.spacing(1.5),
  fontSize: '0.75rem',
  alignSelf: 'center',
}));

const CustomToolbar = () => {
  return (
    <Box sx={{m: 1, display: 'flex', justifyContent: 'space-between'}}>
      <GridToolbarContainer>
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton data-cy="filters-button" />
      </GridToolbarContainer>
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    </Box>
  );
};

const validationSchema = {
  status: ['online', 'offline', 'impaired'],
  cameraStatus: ['online', 'offline'],
  caStatus: ['error', 'warning', 'good'],
  bitrateQuality: ['offline', 'poor', 'good', 'excellent'],
  impairedCameras: ['impaired', 'unimpaired'],
};

const validateUrlState = (urlState) => {
  const validEntries = {};
  Object.keys(urlState).forEach((key) => {
    if (validationSchema[key]) {
      const statuses = urlState[key]
        ? urlState[key].split(',').map((string) => string.trim())
        : [];
      const validStatuses = statuses.filter((status) => {
        return validationSchema[key].includes(status);
      });
      validEntries[key] = validStatuses.join(',');
    }
  });
  return validEntries;
};

const initialFilter = (urlState) => {
  const validUrlState = validateUrlState(urlState);
  const formattedData = [];
  Object.entries(validUrlState).forEach(([field, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formattedData.push({
          field,
          operator: 'is',
          value: capitalize(item),
        });
      });
    } else if (typeof value === 'string') {
      const splitValue = value.split(',');
      splitValue.forEach((item) => {
        formattedData.push({
          id: 1,
          field,
          operator: 'is',
          value: capitalize(item),
        });
      });
    }
  });
  return formattedData;
};

const ChildCellHeader = styled(Typography)(({theme}) => ({
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 500,
  marginBottom: theme.spacing(1),
}));

const statusCell = (params) => {
  const {
    status,
    envrName,
    envrMac,
    lastConnected,
    timezoneName,
    applianceIsOnline,
    descendantCount: isParent,
  } = params.row;
  let icon;
  if (status === 'offline') icon = 'error';
  if (status === 'impaired') icon = 'warning';

  const lastDateFormat = 'DDD, T ZZZZ';
  const lastConnectedDate = DateTime.fromISO(lastConnected).setZone(
    timezoneName,
  );
  const absoluteTime = lastConnectedDate.isValid
    ? lastConnectedDate.toFormat(lastDateFormat)
    : null;

  if (isParent) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'center',
          textTransform: 'capitalize',
          ml: !icon && '24px',
        }}
      >
        {icon && <StatusIcon color={icon} />}
        {status}
      </Box>
    );
  }
  return (
    <List sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
      {envrName && (
        <Typography fontSize="0.8rem" fontWeight="500">
          {envrName}
        </Typography>
      )}
      {envrMac && <Typography fontSize="0.8rem">MAC: {envrMac}</Typography>}
      {applianceIsOnline ? (
        <Typography fontSize="0.8rem" sx={{color: 'grey.600'}}>
          Connected Since: {absoluteTime}
        </Typography>
      ) : (
        <Typography fontSize="0.8rem" sx={{color: 'grey.600'}}>
          Last Connected: {absoluteTime || 'Never Connected'}
        </Typography>
      )}
    </List>
  );
};

const showAdditionalCameras = (rowId, selector) => {
  document
    .querySelectorAll(`.${selector}-${rowId}`)
    .forEach((e) => (e.style.display = 'block'));
  document.querySelector(`.show-${selector}-${rowId}`).style.display = 'none';
};

const cameraStatus = (params) => {
  const {
    id,
    cameras,
    totalCameras,
    offlineCameras,
    descendantCount: isParent,
  } = params.row;
  const icon = offlineCameras > 0 && 'error';
  const childRowCameras = cameras?.filter((camera) => !camera.isOnline) || [];
  const camerasLength = childRowCameras?.length;
  if (isParent) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'center',
          ml: !icon && '24px',
          textTransform: 'capitalize',
        }}
      >
        {icon && <StatusIcon color={icon} />}
        {offlineCameras}/{totalCameras}
      </Box>
    );
  }
  return (
    camerasLength > 0 && (
      <List>
        <ChildCellHeader>Offline Cameras:</ChildCellHeader>
        {childRowCameras.map((camera) => (
          <Box
            key={camera.cameraName}
            className={`cameras-offline-${id}`}
            sx={{
              ':nth-of-type(n+2)': {
                display: 'none',
              },
            }}
          >
            {camera.cameraName}
          </Box>
        ))}
        {camerasLength > 1 && (
          <Typography
            className={`show-cameras-offline-${id}`}
            sx={{color: 'primary.main', cursor: 'pointer'}}
            variant="body2"
            onClick={() => {
              showAdditionalCameras(id, 'cameras-offline');
            }}
          >
            + {camerasLength - 1} more
          </Typography>
        )}
      </List>
    )
  );
};

const ImageHealth = (params, currentUser) => {
  const {
    id,
    cameras,
    totalCameras,
    impairedCameras: totalImpairedCameras,
    descendantCount: isParent,
  } = params.row;
  const [selectedCamera, setSelectedCamera] = useState(null);
  const openImpairmentModal = (camera) => {
    setSelectedCamera(camera);
  };
  const closeImpairmentModal = () => {
    setSelectedCamera(null);
  };
  const icon = totalImpairedCameras > 0 && 'warning';
  const childRowCameras = cameras?.filter((camera) => camera.isImpaired) || [];
  const camerasLength = childRowCameras?.length;
  if (isParent) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'center',
          ml: !icon && '24px',
          textTransform: 'capitalize',
        }}
      >
        {icon && <StatusIcon color={icon} />}
        {totalImpairedCameras}/{totalCameras}
      </Box>
    );
  }

  return (
    camerasLength > 0 && (
      <List>
        {selectedCamera && (
          <Dialog
            maxWidth="lg"
            open={!!selectedCamera}
            onClose={closeImpairmentModal}
          >
            <CameraTamperModal
              cameraData={selectedCamera}
              onClose={closeImpairmentModal}
              currentUser={currentUser}
            />
          </Dialog>
        )}
        <ChildCellHeader>Impaired:</ChildCellHeader>
        {childRowCameras.map((camera) => (
          <Box
            key={camera.id}
            className={`cameras-impaired-${id}`}
            sx={{
              ':nth-of-type(n+2)': {
                display: 'none',
              },
            }}
          >
            <Button onClick={() => openImpairmentModal(camera)}>
              {camera.name}
            </Button>
          </Box>
        ))}
        {camerasLength > 1 && (
          <Typography
            className={`show-cameras-impaired-${id}`}
            sx={{color: 'primary.main', cursor: 'pointer'}}
            variant="body2"
            onClick={() => {
              showAdditionalCameras(id, 'cameras-impaired');
            }}
          >
            + {camerasLength - 1} more
          </Typography>
        )}
      </List>
    )
  );
};

const TooltipTypography = ({title, caption, syncBlurb}) => (
  <Box sx={{display: 'flex', flexDirection: 'column'}}>
    <Typography variant="caption">
      <Typography
        variant="caption"
        component="span"
        sx={{fontWeight: 500, mr: 0.5}}
      >
        {title}
      </Typography>
      {caption}
    </Typography>
    <Typography variant="caption" sx={{mb: 1}}>
      {syncBlurb}
    </Typography>
  </Box>
);

const PosTooltipContent = () => {
  return (
    <Box>
      <TooltipTypography
        title="Current:"
        caption="Less than 48 hours."
        syncBlurb="Sync rate is normal."
      />
      <TooltipTypography
        title="Lagging:"
        caption="48 hours to 72 hours."
        syncBlurb="Missed at least one sync cycle."
      />
      <TooltipTypography
        title="Disrupted:"
        caption="More than 72 hours."
        syncBlurb="Missed more than one sync cycle."
      />
    </Box>
  );
};

const posCell = (params) => {
  const {pos, posStatus, timezoneName, descendantCount: isParent} = params.row;
  const posDate = DateTime.fromISO(pos).setZone(timezoneName);
  const msTimeDiff = DateTime.local()
    .setZone(timezoneName)
    .diff(posDate);
  const relativeDays = msTimeDiff.isValid
    ? Duration.fromMillis(msTimeDiff).toFormat('d')
    : '';
  const absoluteDate = posDate.isValid ? posDate.toFormat('DDD, T ZZZZ') : '';
  let icon;
  if (posStatus === 'Disrupted') icon = 'error';
  if (posStatus === 'Lagging') icon = 'warning';

  if (isParent) {
    return (
      <Box sx={{textTransform: 'capitalize', ml: !icon && '24px'}}>
        {posStatus && icon && <StatusIcon color={icon} />}
        {posStatus}
      </Box>
    );
  }
  return (
    !isParent &&
    pos && (
      <List>
        <ChildCellHeader>Last Received:</ChildCellHeader>
        <Box>
          {relativeDays > 0 ? `${relativeDays} Days ago` : 'Today'}
          <span data-cy="pos-tooltip">
            <Tooltip title={PosTooltipContent()}>
              <ErrorOutlineIcon
                sx={{fontSize: '1rem', ml: 0.5, verticalAlign: 'top'}}
              />
            </Tooltip>
          </span>
        </Box>
        <Typography fontSize="0.8rem" sx={{color: 'grey.600'}}>
          {absoluteDate}
        </Typography>
      </List>
    )
  );
};

const caStatusCell = (params) => {
  const {id, caStatus, cameras, descendantCount: isParent} = params.row;
  let icon;
  if (caStatus === 'ERROR') icon = 'error';
  if (caStatus === 'WARNING') icon = 'warning';

  const warningCameras = [];
  const errorCameras = [];
  if (cameras) {
    cameras.forEach((camera) => {
      if (camera.cameraCaStatus === 'ERROR') {
        errorCameras.push(camera.cameraName);
      }
      if (camera.cameraCaStatus === 'WARNING') {
        warningCameras.push(camera.cameraName);
      }
    });
  }
  const errorLength = errorCameras?.length;
  const warningLength = warningCameras?.length;

  if (isParent) {
    return (
      <Box sx={{textTransform: 'capitalize', ml: !icon && '24px'}}>
        {caStatus && icon && <StatusIcon color={icon} />}
        {caStatus?.toLowerCase()}
      </Box>
    );
  }

  return (
    <Box>
      {errorLength > 0 && (
        <List>
          <ChildCellHeader>Error:</ChildCellHeader>
          {errorCameras.map((cameraName) => (
            <Box
              key={cameraName}
              className={`cameras-ca-error-${id}`}
              sx={{
                ':nth-of-type(n+2)': {
                  display: 'none',
                },
              }}
            >
              {cameraName}
            </Box>
          ))}
          {errorLength > 1 && (
            <Typography
              className={`show-cameras-ca-error-${id}`}
              sx={{color: 'primary.main', cursor: 'pointer'}}
              variant="body2"
              onClick={() => {
                showAdditionalCameras(id, 'cameras-ca-error');
              }}
            >
              + {errorLength - 1} more
            </Typography>
          )}
        </List>
      )}
      {warningLength > 0 && (
        <List>
          <ChildCellHeader>Warning:</ChildCellHeader>
          {warningCameras.map((cameraName) => (
            <Box
              key={cameraName}
              className={`cameras-ca-warning-${id}`}
              sx={{
                ':nth-of-type(n+2)': {
                  display: 'none',
                },
              }}
            >
              {cameraName}
            </Box>
          ))}
          {warningLength > 1 && (
            <Typography
              className={`show-cameras-ca-warning-${id}`}
              sx={{color: 'primary.main', cursor: 'pointer'}}
              variant="body2"
              onClick={() => {
                showAdditionalCameras(id, 'cameras-ca-warning');
              }}
            >
              + {warningLength - 1} more
            </Typography>
          )}
        </List>
      )}
    </Box>
  );
};

const earliestVideoCell = (params) => {
  const {earliestVideo, cameras, descendantCount: isParent} = params.row;

  if (isParent) {
    return (
      <List sx={{py: 0.5}}>
        <Typography fontSize="0.8rem" data-cy="relative-days">
          {earliestVideo?.[1]}
        </Typography>
        <Typography
          fontSize="0.8rem"
          sx={{color: 'grey.600'}}
          data-cy="absolute-days"
        >
          {earliestVideo?.[0]}
        </Typography>
      </List>
    );
  }
  return (
    !isParent && (
      <List>
        <ChildCellHeader>Additional Info:</ChildCellHeader>
        <EarliestVideoModal cameras={cameras} />
      </List>
    )
  );
};

const BitrateTooltipContent = () => (
  <Box>
    <TooltipTypography
      title="Excellent:"
      caption="More than 1Mbps."
      syncBlurb="Optimal viewing experience."
    />
    <TooltipTypography
      title="Good:"
      caption="601 Kbps to 1Mbps."
      syncBlurb="Some buffering or loss of quality."
    />
    <TooltipTypography
      title="Poor:"
      caption="Less than 600Kbps."
      syncBlurb="Limited viewer experience, frequent buffering."
    />
    <TooltipTypography
      title="Offline:"
      caption="No signal detected."
      syncBlurb="Optimal viewing experience."
    />
    <Typography variant="caption">Speed averaged over 7 days.</Typography>
  </Box>
);

const bitrateCell = (params) => {
  const {bitrateQuality, bitrate, descendantCount: isParent} = params.row;
  let icon;
  if (bitrateQuality === 'offline') icon = 'error';
  if (bitrateQuality === 'poor') icon = 'warning';

  const bitrateMbps = params.row.bitrate / 1000000;
  const truncatedBitrate = Math.trunc(bitrateMbps * 100) / 100; // 2 decimal places
  const childBitrate =
    bitrate > 0 ? `${truncatedBitrate.toFixed(2)} Mbps ` : 'Offline ';
  if (isParent) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'center',
          textTransform: 'capitalize',
          ml: !icon && '24px',
        }}
      >
        {icon && <StatusIcon color={icon} />}
        {bitrateQuality}
      </Box>
    );
  }
  return (
    !isParent && (
      <List>
        <ChildCellHeader>Average Speed:</ChildCellHeader>
        <li>
          {childBitrate}
          <span data-cy="bitrate-tooltip">
            <Tooltip title={BitrateTooltipContent()}>
              <ErrorOutlineIcon sx={{fontSize: '1rem', verticalAlign: 'top'}} />
            </Tooltip>
          </span>
        </li>
      </List>
    )
  );
};

const rowActionsCell = (params, isEnvrAdmin, currentUser) => {
  const isParent = params.row.descendantCount;
  return (
    isParent && (
      <Fragment>
        <Tooltip title="Site Page">
          <IconButton
            component={Link}
            to={`/sites/${params.row.siteId}`}
            target="_blank"
            aria-label="open site page"
          >
            <PlaceIcon />
          </IconButton>
        </Tooltip>
        {isEnvrAdmin && (
          <Tooltip title="Site Settings">
            <IconButton
              component={Link}
              to={
                isNewNavigationWebEnabled(currentUser)
                  ? `/home/site-settings/devices?siteId=${params.row.siteId}`
                  : `/sites/${params.row.siteId}/settings/devices`
              }
              target="_blank"
              aria-label="open site devices settings"
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        )}
      </Fragment>
    )
  );
};

const GroupingCell = (params) => {
  const {rowNode, row} = params;
  const isParent = row.descendantCount;
  const rootProps = useGridRootProps();

  const Icon = rowNode.childrenExpanded
    ? rootProps.slots.treeDataCollapseIcon
    : rootProps.slots.treeDataExpandIcon;

  if (isParent) {
    return (
      <Box sx={{display: 'flex'}}>
        <Box sx={{alignSelf: 'center'}}>
          {row.descendantCount > 0 && (
            <IconButton size="small" aria-label="expand row">
              <Icon fontSize="inherit" />
            </IconButton>
          )}
        </Box>
        <Typography variant="body2" sx={{alignSelf: 'center'}}>
          {row.name}
        </Typography>
      </Box>
    );
  }
  return <Box sx={{height: 80}} />;
};

const getTreeDataPath = (row) => row.hierarchy;

const GridList = (props) => {
  const {
    posAllowed,
    caStatusAllowed,
    setUrlState,
    urlState,
    logger,
    currentUser,
  } = props;
  const isEnvrAdmin = allowed(currentUser, [ENVR_ADMIN]);
  const apiRef = useGridApiRef();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [sortModel, setSortModel] = useState([]);
  const [searchParam, setSearchParam] = useState();
  const [filterValues, setFilterValues] = useState(validateUrlState(urlState));
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });

  const handleFilterChange = (filters) => {
    apiRef.current.hideFilterPanel();
    if (filters !== undefined) {
      const formatFilters = Object.fromEntries(
        Object.entries(filters).map(([key, value]) => [
          key,
          value.join(',').toLowerCase(),
        ]),
      );
      setUrlState(formatFilters);
      setFilterValues(formatFilters);
    }
  };

  const CustomFilterPanel = () => (
    <FilterPanel
      caStatusAllowed={caStatusAllowed}
      filterValues={validateUrlState(filterValues)}
      onFilterChange={handleFilterChange}
      loading={loading}
    />
  );

  const handleSearch = (filterModel) => {
    setSearchParam(filterModel?.quickFilterValues?.join(' '));
  };

  // __tree_data_group__ is used to hide columns
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    __tree_data_group__: false,
    pos: posAllowed,
    caStatus: caStatusAllowed,
  });
  // The basic assumption is that every site will have at least one ENVR.
  // Until we make the API call, it is unknown if there are more.
  // This is a counter to provide new additional ENVRs with an ID.
  // With pagination limit of 100, it is unlikely for that number to be reached.
  const siblingId = useRef(1000000);

  const getTogglableColumns = (columns) => {
    // Hides the rowActions and grouping column from the column
    // filter and POS or Cloud Archive depending on allowed prop
    const hideColumnFilter = [
      '__row_group_by_columns_group__',
      '__tree_data_group__',
      'rowActions',
      'name',
    ];
    if (!posAllowed) {
      hideColumnFilter.push('pos');
    }
    if (!caStatusAllowed) {
      hideColumnFilter.push('caStatus');
    }
    return columns
      .filter((column) => !hideColumnFilter.includes(column.field))
      .map((column) => column.field);
  };

  useEffect(
    () => {
      const fetchData = async () => {
        setLoading(true);
        try {
          const data = await getHardwareHealth(
            paginationModel?.page,
            paginationModel?.pageSize,
            formatText(sortModel[0]?.field),
            sortModel[0]?.sort,
            searchParam,
            filterValues?.status,
            filterValues?.bitrateQuality,
            filterValues?.cameraStatus,
            filterValues?.caStatus,
            filterValues?.impairedCameras,
          );
          // DataGrid treeData rows need to have at least one child row.
          // On data, placeholder children are added with an id of parent.id+1
          // On row expansion, the child is updated with correct data during
          // the api call, and additional children can be added
          if (data.results) {
            setRowCount(data.count);
            let idCounter = 0;
            const formattedRows = [];
            data.results.forEach((site) => {
              formattedRows.push({
                ...site,
                id: idCounter,
                hierarchy: [site.siteId],
                descendantCount: 1,
                earliestVideo: formatDateTime(
                  site.earliestVideo,
                  site.timezoneName,
                ),
              });
              // id and hierarchy required for placeholder
              // POS data comes from parent api
              formattedRows.push({
                id: idCounter + 1,
                hierarchy: [site.siteId, ''],
                pos: site.pos,
                timezoneName: site.timezoneName,
              });
              idCounter += 2;
            });
            setRows(formattedRows);
            setUrlState(filterValues);
          }
        } catch (error) {
          logger.error('Failed to load system health data', {}, error);
        }
        setLoading(false);
      };
      fetchData();
    },
    [
      sortModel,
      paginationModel,
      logger,
      setUrlState,
      filterValues,
      searchParam,
    ],
  );

  // The grouping column is hidden by default as it cannot be sorted
  const groupingColDef = {
    headerName: 'Group',
    flex: 1.1,
    hideDescendantCount: true,
    valueGetter: (params) => {
      return params?.row?.name;
    },
    renderCell: GroupingCell,
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Site Name',
      flex: 1,
      filterable: false,
      valueGetter: (params) => {
        return capitalize(params?.row?.name);
      },
      renderCell: GroupingCell,
    },
    {
      field: 'status',
      headerName: 'Appliance Status',
      flex: 0.9,
      type: 'singleSelect',
      valueOptions: ['Online', 'Impaired', 'Offline'],
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is',
      ),
      valueGetter: (params) => {
        return capitalize(params?.row?.status);
      },
      renderCell: statusCell,
    },
    {
      field: 'cameraStatus',
      headerName: 'Cameras Offline / Total',
      flex: 0.9,
      type: 'singleSelect',
      valueOptions: ['Online', 'Offline'],
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is',
      ),
      valueGetter: (params) => {
        return params?.row?.offlineCameras > 0 ? 'Offline' : 'Online';
      },
      renderCell: cameraStatus,
    },
    {
      field: 'impairedCameras',
      headerName: 'Image Health',
      flex: 0.8,
      filterable: true,
      type: 'singleSelect',
      valueOptions: ['Impaired', 'Unimpaired'],
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is',
      ),
      valueGetter: (params) => {
        return params?.row?.impairedCameras > 0 ? 'Impaired' : 'Unimpaired';
      },
      renderCell: (params) => ImageHealth(params, currentUser),
    },
    {
      field: 'pos',
      headerName: 'POS',
      flex: 0.7,
      width: 80,
      minWidth: 60,
      maxWidth: 130,
      filterable: false,
      valueGetter: (params) => {
        return params?.row?.posStatus;
      },
      renderCell: posCell,
    },
    {
      field: 'caStatus',
      headerName: 'Cloud Archive Status',
      flex: 0.8,
      filterable: caStatusAllowed,
      type: 'singleSelect',
      valueOptions: ['Good', 'Warning', 'Error'],
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is',
      ),
      valueGetter: (params) => {
        return capitalize(params?.row?.caStatus);
      },
      renderCell: caStatusCell,
    },
    {
      field: 'earliestVideo',
      headerName: 'Oldest Video Available',
      flex: 0.9,
      filterable: false,
      type: 'date',
      valueGetter: (params) => {
        const valueFormatting = params?.row?.earliestVideo;
        return new Date(valueFormatting);
      },
      renderCell: earliestVideoCell,
    },
    {
      field: 'bitrateQuality',
      headerName: 'Upload Speed',
      width: 100,
      minWidth: 65,
      maxWidth: 200,
      flex: 0.7,
      type: 'singleSelect',
      valueOptions: ['Excellent', 'Good', 'Poor', 'Offline'],
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is',
      ),
      valueGetter: (params) => {
        return capitalize(params?.row?.bitrateQuality);
      },
      renderCell: bitrateCell,
    },
    {
      field: 'rowActions',
      headerName: '',
      width: 100,
      minWidth: 40,
      maxWidth: 100,
      flex: 0.7,
      filterable: false,
      headerClassName: 'rowActions-header',
      cellClassName: 'rowActions-cell',
      renderCell: (params) =>
        rowActionsCell({...params}, isEnvrAdmin, currentUser),
    },
  ];

  const getAdditionalData = async (siteId, childRow) => {
    const siteCameras = await getCamerasBySiteId(siteId);
    const additionalCamData = childRow.reduce((acc, item) => {
      const updatedCameras = item.cameras.map((camera) => {
        const impairedCamera = siteCameras.find(
          (cam) => cam.id === `${camera.id}`,
        );
        return impairedCamera
          ? {
              ...camera,
              ...impairedCamera,
              hardwareId: impairedCamera.id,
              hardwareName: impairedCamera.name,
              hardwareStatus: impairedCamera.isImpaired
                ? 'Impaired'
                : 'Unimpaired',
              displayMessage: impairedCamera.isImpaired
                ? 'ImageAlert image issue detected'
                : 'ImageAlert image quality restored',
              earliestData: impairedCamera.earliestVideoGmt,
              applianceSensorNum: impairedCamera.channel,
            }
          : camera;
      });
      acc.push({
        ...item,
        cameras: updatedCameras,
      });
      return acc;
    }, []);
    return additionalCamData;
  };

  const handleRowClick = async (params) => {
    const rowNode = apiRef.current.getRowNode(params.id);
    if (rowNode.type === 'leaf') {
      return null;
    }
    if (rowNode.childrenExpanded || params.row.childrenFetched) {
      return apiRef.current.setRowChildrenExpansion(
        params.id,
        !rowNode.childrenExpanded,
      );
    }

    setLoading(true);

    // On initial row expansion, if there is no child row present, it will
    // throw an error. But if there is already a child placeholder,
    // it will expand and call the api and update the child, as well as
    // create the appropriate amount of sibling rows.
    try {
      let childRow = await getHealthDetailsForSite(rowNode.groupingKey);
      const hasImpairedCameras = childRow.some((appliance) =>
        appliance.cameras?.find((cam) => cam.isImpaired === true),
      );
      if (hasImpairedCameras) {
        childRow = await getAdditionalData(params.row.siteId, childRow);
      }

      const createSiblingRows = (n) => {
        siblingId.current += n;
        return {
          hierarchy: [rowNode.groupingKey, `${siblingId.current}`],
          id: siblingId.current,
        };
      };
      apiRef.current.updateRows([{...params.row, childrenFetched: true}]);
      apiRef.current.updateRows([{id: params.row.id + 1, ...childRow[0]}]);
      const totalSiblings = childRow.length;
      let siblingCounter = 1;
      while (siblingCounter < totalSiblings) {
        apiRef.current.updateRows([createSiblingRows(siblingCounter)]);
        apiRef.current.updateRows([
          {id: siblingId.current, ...childRow[siblingCounter]},
        ]);
        siblingCounter += 1;
      }
    } catch (error) {
      logger.error('Failed to load site data', {}, error);
    } finally {
      if (rowNode && rowNode.type === 'group') {
        setLoading(false);
        apiRef.current.setRowChildrenExpansion(
          params.id,
          !rowNode.childrenExpanded,
        );
      }
    }
    return null;
  };

  return (
    <Box sx={{height: '85vh', width: '97vw'}}>
      <DataGridPro
        treeData
        apiRef={apiRef}
        loading={loading}
        rows={rows}
        initialState={{
          filter: {
            filterModel: {
              items: urlState ? initialFilter(urlState) : [],
              quickFilterExcludeHiddenColumns: true,
              logicOperator: GridLogicOperator.And,
            },
          },
        }}
        onRowClick={handleRowClick}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        getTreeDataPath={getTreeDataPath}
        getRowHeight={() => 'auto'}
        groupingColDef={groupingColDef}
        disableChildrenSorting
        disableChildrenFiltering
        disableRowSelectionOnClick
        disableColumnMenu
        sortingMode="server"
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        filterMode="server"
        filterModel={{
          items: urlState ? initialFilter(urlState) : [],
        }}
        onFilterModelChange={handleSearch}
        pagination
        rowCount={rowCount}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{
          toolbar: CustomToolbar,
          filterPanel: CustomFilterPanel,
        }}
        slotProps={{
          // currently theres a bug where it forces native
          // select/textfield, but turning it off casuses console errors
          // baseSelect: {native: false},
          // baseTextField: {native: false},
          columnsPanel: {
            getTogglableColumns,
          },
          filterPanel: {
            logicOperators: [GridLogicOperator.And],
            sx: {
              '& .MuiDataGrid-filterFormLogicOperatorInput': {display: 'none'},
              '& .MuiDataGrid-filterFormValueInput': {width: 'auto'},
            },
          },
        }}
        localeText={{
          toolbarQuickFilterPlaceholder: 'Site Name or MAC',
          noRowsLabel: 'No results found.',
        }}
        sx={{
          // removes blue focus outline on cell/header select
          [`& .${gridClasses.cell}:focus, & .${
            gridClasses.cell
          }:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${
            gridClasses.columnHeader
          }:focus-within`]: {
            outline: 'none',
          },
          '& .MuiDataGrid-iconButtonContainer:has([title="Sort"])': {
            width: '',
          },
          // enables column header text wrapping
          '& .MuiDataGrid-columnHeaderTitle': {
            textWrap: 'pretty',
            lineHeight: '18px',
          },
          // hides row actions header
          '& .rowActions-header': {
            display: 'none',
          },
          '& .rowActions-cell': {
            justifyContent: 'center',
            p: 0,
          },
          '& .rowActions-cell a': {
            p: [0, 0.25, 0.5, 1],
          },
          // aligns appliance stats children info
          '& .MuiDataGrid-cell:has(.MuiTypography-body1)': {
            alignItems: 'flex-start',
          },
          // presets for row spacing MuiDataGrid-columnHeaders
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: 0},
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: 0.5,
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: 1,
          },
        }}
      />
    </Box>
  );
};
export default GridList;
