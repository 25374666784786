import React from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  ListItemSecondaryAction,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {WarningAmberOutlined} from '@mui/icons-material';

import PropTypes from 'prop-types';

import {
  subscriptionDisplayTypes,
  subscriptionEventTypes,
  priorityContactsDisplayText,
  priorities,
  formatUsername,
} from './utils';

const requirePrimaryContacts = (eventType, isEnvysionMonitoring) =>
  eventType === subscriptionEventTypes.panic ||
  (eventType === subscriptionEventTypes.video && isEnvysionMonitoring);

const displayNoContactWarning = (eventType, isEM) => (
  <Box
    sx={{
      display: 'flex',
      gap: (theme) => theme.spacing(1),
      backgroundColor: (theme) => theme.palette.warning.fill,
      color: (theme) => theme.palette.warning.darker,
      padding: (theme) => theme.spacing(1),
    }}
  >
    <WarningAmberOutlined sx={{color: (theme) => theme.palette.warning.main}} />
    <Typography data-cy="no-contacts-warning">
      No
      {requirePrimaryContacts(eventType, isEM) ? ' Priority ' : ' '}
      Contact Assigned
    </Typography>
  </Box>
);

const getPriority = (user) =>
  user?.subscriptions?.find((s) => s.type === 'noonlight')?.priority;

const getAllSubscriptions = (subscriptions) =>
  subscriptions.map((it) => subscriptionDisplayTypes[it.type]).join(', ');

const displayFirstContact = (firstContact, eventType, isEM) =>
  firstContact ? (
    <Box
      data-cy={`${eventType}_first_contact`}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="body1">
        {formatUsername(firstContact.firstName, firstContact.lastName)}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {isEM && priorityContactsDisplayText(getPriority(firstContact))}
        {!isEM && getAllSubscriptions(firstContact.subscriptions)}
      </Typography>
    </Box>
  ) : (
    displayNoContactWarning(eventType, isEM)
  );

const NotificationSection = (props) => {
  const {
    expanded,
    sectionName,
    subscribers = [],
    onNotificationsDialogOpen,
    eventType,
    userSubscriptionsVisible,
    isEM = false,
  } = props;

  const firstContact = requirePrimaryContacts(eventType, isEM)
    ? subscribers.find((user) => getPriority(user) === priorities.first)
    : subscribers[0];

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      disableGutters
      sx={{
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={{
          ...(!sectionName && firstContact && expanded && {display: 'none'}),
          '.MuiAccordionSummary-content': {
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
          },
          padding: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...(sectionName && {height: (theme) => theme.spacing(6)}),
            marginBottom: (theme) => theme.spacing(1),
          }}
        >
          {sectionName && <Typography variant="h6">{sectionName}</Typography>}
          {expanded && onNotificationsDialogOpen && (
            <Button
              variant="outlined"
              onClick={onNotificationsDialogOpen}
              aria-label={`Edit ${eventType}`}
            >
              Edit
            </Button>
          )}
        </Box>
        {expanded
          ? !firstContact && displayNoContactWarning(eventType, isEM)
          : displayFirstContact(firstContact, eventType, isEM)}
      </AccordionSummary>
      {subscribers && subscribers.length > 0 && (
        <AccordionDetails sx={{padding: 0}}>
          <List
            sx={{paddingTop: 0, paddingBottom: 0}}
            data-cy={`${eventType}_contacts`}
          >
            {subscribers.map((user) => (
              <ListItem
                key={`${user.uuid}`}
                sx={{
                  paddingLeft: 0,
                  ...(!userSubscriptionsVisible && {py: 0.25}),
                }}
              >
                <ListItemText
                  primary={formatUsername(user.firstName, user.lastName)}
                />
                <ListItemSecondaryAction
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    right: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                    }}
                  >
                    <Typography variant="body1" fontWeight="500">
                      {priorityContactsDisplayText(getPriority(user))}
                    </Typography>
                    {userSubscriptionsVisible && (
                      <Typography variant="body2">
                        {getAllSubscriptions(user.subscriptions)}
                      </Typography>
                    )}
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

NotificationSection.propTypes = {
  expanded: PropTypes.bool.isRequired,
  sectionName: PropTypes.string,
  subscribers: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      subscriptions: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          priority: PropTypes.number,
          subscriptionId: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ),
  onNotificationsDialogOpen: PropTypes.func,
  eventType: PropTypes.string.isRequired,
  userSubscriptionsVisible: PropTypes.bool,
  isEM: PropTypes.bool,
};
NotificationSection.defaultProps = {
  onNotificationsDialogOpen: undefined,
  userSubscriptionsVisible: true,
  isEM: false,
  subscribers: [],
  sectionName: undefined,
};

export default NotificationSection;
