import React, {useEffect} from 'react';

import CloudQueue from '@mui/icons-material/CloudQueue';

import {useApi} from '../../../../shared/hooks';
import {isNewNavigationWebEnabled} from '../../../../shared/util/user';
import {getCloudArchiveStatusCount} from '../../../../api/cloudArchive';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {openInOneTab} from '../../utils';
import DashboardStatusWidget from '../../../../shared/components/dashboardComponent/dashboardStatusWidget';

const CloudArchiveWidget = ({widgetConfig, currentUser}) => {
  const [cloudArchiveStatusCount, loading, isError, getStatusCount] = useApi(
    getCloudArchiveStatusCount,
  );

  useEffect(
    () => {
      getStatusCount();
    },
    [getStatusCount],
  );

  const statusCount = cloudArchiveStatusCount?.reduce(
    (acc, item) => {
      acc[item.status.toLowerCase()] = item.count;
      return acc;
    },
    {good: 0, warning: 0, error: 0},
  );

  const pathname = isNewNavigationWebEnabled(currentUser)
    ? '/home/system-health'
    : '/hardware-health';

  const content = {
    dualPanel: true,
    panels: [
      {
        title: 'Cloud Archive',
        count: statusCount?.error,
        path: pathname,
        search: '?caStatus=error',
        state: {fromDashboard: true},
        target: openInOneTab(currentUser),
        buttonText: 'Sites with Failures',
      },
      {
        icon: <CloudQueue sx={{fontSize: '1.25rem'}} />,
        count: statusCount?.warning,
        path: pathname,
        search: '?caStatus=warning',
        state: {fromDashboard: true},
        target: openInOneTab(currentUser),
        buttonText: 'Sites with Warnings',
      },
    ],
  };

  return (
    <DashboardStatusWidget
      id={widgetConfig.widgetReference}
      altContent={content}
      isError={isError}
      loading={loading}
      {...widgetConfig.properties}
    />
  );
};

export default withCurrentUser(CloudArchiveWidget);
