import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {TextField, Box, Alert, AlertTitle} from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import {useDialog} from '../../../../shared/hooks';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import Spinner from '../../../../shared/components/spinner';
import SectionTitleBar from '../SectionTitleBar';
import NoonlightPermitDialog from './NoonlightPermitDialog';
import {patchSiteAlarmData} from '../../../../api/alarms';

/** section for additional security related information */
const NoonlightPermitSection = ({
  selectedSiteId,
  snackbar,
  alarmModeState,
  refreshAlarmMode,
}) => {
  const [
    permitDialogOpen,
    handlePermitDialogOpen,
    handlePermitDialogClose,
  ] = useDialog();

  const {state, siteStatus} = alarmModeState;
  const permitNumber = siteStatus?.securityPermit;
  const isLoading = state === 'LOADING';
  const isError = state === 'ERROR';

  const formStructure = [
    {
      name: 'security_permit',
      label: 'Security Permit Number',
      defaultValue: permitNumber || '',
      validation: Yup.string().max(32, 'Must be 32 characters or less'),
    },
  ];

  if (isError) {
    return (
      <Alert severity="error">
        <AlertTitle>Additional Details</AlertTitle>
        Failed to fetch the content
      </Alert>
    );
  }

  return (
    <Fragment>
      <Box data-cy="permit-number-section">
        <SectionTitleBar
          title="Additional Details"
          icon={<SupportAgentIcon />}
          onClick={handlePermitDialogOpen}
          isActionIconVisible={handlePermitDialogOpen && !isLoading}
        />
        {isLoading ? (
          <Box sx={{margin: 2.5}}>
            <Spinner size={40} color="primary" />
          </Box>
        ) : (
          formStructure.map((entry) => (
            <TextField
              key={entry.name}
              label={entry.label}
              name={entry.name}
              value={entry.defaultValue || '...'}
              margin="normal"
              variant="outlined"
              disabled
              fullWidth
            />
          ))
        )}
      </Box>
      <NoonlightPermitDialog
        formStructure={formStructure}
        dialogOpen={permitDialogOpen}
        onDialogClose={handlePermitDialogClose}
        onSubmit={async (data) => {
          await patchSiteAlarmData(selectedSiteId, data);
        }}
        onSuccess={() => {
          snackbar.success('Successfully updated additional details');
          refreshAlarmMode();
        }}
        onError={() => {
          snackbar.error('Failed to update additional details');
        }}
      />
    </Fragment>
  );
};

NoonlightPermitSection.propTypes = {
  selectedSiteId: PropTypes.string.isRequired,
  snackbar: PropTypes.shape({}).isRequired,
  alarmModeState: PropTypes.shape({}).isRequired,
  refreshAlarmMode: PropTypes.func,
};

NoonlightPermitSection.defaultProps = {
  refreshAlarmMode: () => {},
};

export default withCurrentUser(NoonlightPermitSection);
