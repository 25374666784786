import React from 'react';

import PageLoader from '../../../../shared/components/pageLoader';
import {getSiteAccessUsers} from '../../../../api/sites';
import SiteSettingsWrapperNewNavigation from '../utils/SiteSettingsWrapperNewNavigation';

const SiteAccessNewNavigation = () => (
  <SiteSettingsWrapperNewNavigation name="site-access-page-content">
    {(selectedSite, reload) => {
      return (
        <PageLoader
          key="site-access"
          page={() => import('../siteAccess')}
          reload={reload}
          resources={{
            users: () => getSiteAccessUsers(selectedSite.id),
          }}
          siteId={selectedSite.id}
        />
      );
    }}
  </SiteSettingsWrapperNewNavigation>
);

export default SiteAccessNewNavigation;
