import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import {ArrowDropDown} from '@mui/icons-material';

import SiteSelectionMenu from '../../../../shared/components/siteSelectionMenu/SiteSelectionMenu';
import {flattenSitesListForSearch} from '../../../../shared/util/sites';

const SitePickerSettingsNewNavigation = ({
  sitesList,
  selectedSite,
  handleSiteSelect,
}) => {
  return (
    <SiteSelectionMenu
      data-cy="site-selection-menu"
      siteSearchList={
        !isEmpty(sitesList)
          ? flattenSitesListForSearch(sitesList, sitesList.name)
          : []
      }
      title={selectedSite.name || 'Select...'}
      rootCategory={sitesList}
      setCurrentSite={async (e) => {
        if (e.currentSite) {
          handleSiteSelect(e);
        }
      }}
      dropdownProps={{
        variant: 'outlined',
        size: 'small',
        InputProps: {
          endAdornment: <ArrowDropDown />,
          style: {height: 48}, // 48px is set to keep the consistency between settings pages and to fit the tabbar height
        },
        sx: {height: '100%', width: '100%'},
      }}
      autofocus={!selectedSite.id}
    />
  );
};
SitePickerSettingsNewNavigation.propTypes = {
  handleSiteSelect: PropTypes.func.isRequired,
  sitesList: PropTypes.shape({}),
  selectedSite: PropTypes.shape({}),
};
SitePickerSettingsNewNavigation.defaultProps = {
  sitesList: undefined,
  selectedSite: undefined,
};

export default SitePickerSettingsNewNavigation;
