import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import NotificationsIcon from '@mui/icons-material/Notifications';
import {Box} from '@mui/material';

import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {useDialog} from '../../../../shared/hooks';
import Spinner from '../../../../shared/components/spinner';
import {useSspNotifications} from '../notifications/hooks';
import {
  getSubscribersForSection,
  subscriptionEventTypes,
} from '../notifications/utils';
import NotificationSection from '../notifications/NotificationSection';
import NotificationDialog from '../notifications/NotificationDialog';
import SectionTitleBar from '../SectionTitleBar';

/** Safety button subscriptions controller */
const SafetyButtonNotifications = ({site}) => {
  const [
    notificationsDialogOpen,
    handleNotificationsDialogOpen,
    handleNotificationsDialogClose,
  ] = useDialog();

  const {loading, subscribers} = useSspNotifications(
    site.id,
    notificationsDialogOpen,
  );

  return (
    <Fragment>
      <Box data-cy="safety-button-notifications">
        <Box sx={{marginBottom: 1}}>
          <SectionTitleBar
            title="Notifications"
            icon={<NotificationsIcon />}
            onClick={handleNotificationsDialogOpen}
            isActionIconVisible={handleNotificationsDialogOpen && !loading}
          />
        </Box>

        {loading ? (
          <Spinner size={40} color="primary" />
        ) : (
          <NotificationSection
            expanded
            subscribers={getSubscribersForSection(
              subscriptionEventTypes.panic,
              subscribers,
            )}
            eventType={subscriptionEventTypes.panic}
            userSubscriptionsVisible={false}
            isEM
          />
        )}
        <NotificationDialog
          open={notificationsDialogOpen}
          onClose={handleNotificationsDialogClose}
          site={site}
          eventType={subscriptionEventTypes.panic}
          isEM
        />
      </Box>
    </Fragment>
  );
};

SafetyButtonNotifications.propTypes = {
  site: PropTypes.shape({id: PropTypes.string}).isRequired,
};

SafetyButtonNotifications.defaultProps = {};

export default withCurrentUser(SafetyButtonNotifications);
