import React from 'react';
import {Box, Grid} from '@mui/material';

import {useSiteSelector} from '../../../../shared/hooks';
import withUrlState from '../../../../shared/components/withUrlState';
import SitePickerSettingsNewNavigation from './SitePickerSettingsNewNavigation';

const SiteSettingsNewNavigation = ({urlState, setUrlState, name, children}) => {
  const [selectedSite, sitesList, reload, handleSiteSelect] = useSiteSelector(
    urlState,
    setUrlState,
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      data-cy={name}
      sx={{px: {xs: 2, sm: 2.5, md: 3, lg: 5}}}
    >
      <Grid
        container
        justifyContent="end"
        marginBottom={2}
        marginTop={{sm: 2, md: 0}}
      >
        <Grid item xs={12} md={4} lg={3}>
          <SitePickerSettingsNewNavigation
            sitesList={sitesList}
            selectedSite={selectedSite}
            handleSiteSelect={handleSiteSelect}
          />
        </Grid>
      </Grid>
      {selectedSite.id && children(selectedSite, reload)}
    </Box>
  );
};

export default withUrlState()(SiteSettingsNewNavigation);
