import React from 'react';

import PageLoader from '../../../../shared/components/pageLoader';
import SiteSettingsWrapperNewNavigation from '../utils/SiteSettingsWrapperNewNavigation';

const ConfigurationNewNavigation = () => (
  <SiteSettingsWrapperNewNavigation name="configuration-page-content">
    {(selectedSite, reload) => {
      return (
        <PageLoader
          key="configuration"
          page={() => import('../configuration')}
          propsToIgnoreUpdate={['site']}
          reload={reload}
          site={selectedSite}
        />
      );
    }}
  </SiteSettingsWrapperNewNavigation>
);

export default ConfigurationNewNavigation;
