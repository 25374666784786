import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {DateTime} from 'luxon';
import capitalize from 'lodash/capitalize';

import {
  Box,
  Button,
  Dialog,
  IconButton,
  Paper,
  Typography,
  styled,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CircularProgress from '@mui/material/CircularProgress';
import PlaceIcon from '@mui/icons-material/Place';
import SettingsIcon from '@mui/icons-material/Settings';

import allowed, {ENVR_ADMIN} from '../../../shared/util/allowed';
import {isMobileApp} from '../../../shared/util/general';

import {getHealthDetailsForSite} from '../../../api/hardwareHealth';
import {getCamerasBySiteId} from '../../../api/cameras';

import CameraTamperModal from '../../../shared/components/cameraTamperModal';
import EarliestVideoModal from '../EarliestVideoModal';
import OfflineCamerasModal from './OfflineCamerasModal';
import CloudArchiveModal from './CloudArchiveModal';
import {isNewNavigationWebEnabled} from '../../../shared/util/user';

const Card = styled(Paper)(({theme}) => ({
  width: '85vw',
  margin: theme.spacing(2, 'auto'),
  padding: theme.spacing(2),
}));

const StyledIcon = styled(CircleIcon)(({theme}) => ({
  marginRight: theme.spacing(2),
  fontSize: '1.0rem',
  alignSelf: 'center',
}));

const StatusIcon = ({icon}) => {
  if (icon) {
    return <StyledIcon color={icon} />;
  }
  return <Box sx={{ml: '32px'}} />;
};

const LoadingContainer = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(1.5),
}));

const ApplianceStatus = ({status}) => {
  let icon;
  if (status === 'offline') icon = 'error';
  if (status === 'impaired') icon = 'warning';

  return (
    <Box sx={{display: 'flex', mb: 0.5}}>
      <StatusIcon icon={icon} />
      <Typography sx={{width: '40%'}}>Appliance:</Typography>
      <Typography className="card-status" sx={{fontWeight: 500, ml: 0.5}}>
        {capitalize(status) || 'Unavailable'}
      </Typography>
    </Box>
  );
};

const OfflineCameras = ({offlineCameras, totalCameras}) => {
  const icon = offlineCameras > 0 && 'error';
  const validIntegers =
    Number.isInteger(offlineCameras) && Number.isInteger(totalCameras);
  const offlineTotal = validIntegers
    ? `${offlineCameras} / ${totalCameras} Offline`
    : 'Unavailable';

  return (
    <Box sx={{display: 'flex', mb: 0.5}}>
      <StatusIcon icon={icon} />
      <Typography sx={{width: '40%'}}>Cameras:</Typography>
      <Typography
        className="card-offline-cameras"
        sx={{fontWeight: 500, ml: 0.5}}
      >
        {offlineTotal}
      </Typography>
    </Box>
  );
};

const ImageHealth = ({impairedCameras, totalCameras}) => {
  const icon = impairedCameras > 0 && 'warning';
  const validIntegers =
    Number.isInteger(impairedCameras) && Number.isInteger(totalCameras);
  const impairedTotal = validIntegers
    ? `${impairedCameras} / ${totalCameras} Impaired`
    : 'Unavailable';

  return (
    <Box sx={{display: 'flex', mb: 0.5}}>
      <StatusIcon icon={icon} />
      <Typography sx={{width: '40%'}}>Image Health:</Typography>
      <Typography
        className="card-impaired-cameras"
        sx={{fontWeight: 500, ml: 0.5}}
      >
        {impairedTotal}
      </Typography>
    </Box>
  );
};

const POS = ({posAllowed, posStatus}) => {
  let icon;
  if (posStatus === 'Disrupted') icon = 'error';
  if (posStatus === 'Lagging') icon = 'warning';

  return (
    posAllowed && (
      <Box sx={{display: 'flex', mb: 0.5}}>
        <StatusIcon icon={icon} />
        <Typography sx={{width: '40%'}}>POS:</Typography>
        <Typography className="card-pos" sx={{fontWeight: 500, ml: 0.5}}>
          {posStatus || 'Unavailable'}
        </Typography>
      </Box>
    )
  );
};

const CloudArchiveStatus = ({caStatusAllowed, caStatus}) => {
  let icon;
  if (caStatus === 'ERROR') icon = 'error';
  if (caStatus === 'WARNING') icon = 'warning';

  return (
    caStatusAllowed && (
      <Box sx={{display: 'flex', mb: 0.5}}>
        <StatusIcon icon={icon} />
        <Typography sx={{width: '40%'}}>Cloud Archive:</Typography>
        <Typography className="card-ca-status" sx={{fontWeight: 500, ml: 0.5}}>
          {capitalize(caStatus) || 'Not Enabled'}
        </Typography>
      </Box>
    )
  );
};

const OldestVideo = ({earliestVideo}) => {
  return (
    <Box sx={{display: 'flex', mb: 0.5}}>
      <Box sx={{ml: '32px'}} />
      <Typography sx={{width: '40%'}}>Oldest Video:</Typography>
      <Typography
        className="card-earliest-video"
        sx={{fontWeight: 500, ml: 0.5}}
      >
        {earliestVideo || 'Unavailable'}
      </Typography>
    </Box>
  );
};

const UploadSpeed = ({bitrateQuality}) => {
  let icon;
  if (bitrateQuality === 'offline') icon = 'error';
  if (bitrateQuality === 'poor') icon = 'warning';

  return (
    <Box sx={{display: 'flex', mb: 0.5}}>
      <StatusIcon icon={icon} />
      <Typography sx={{width: '40%'}}>Upload Speed:</Typography>
      <Typography
        className="card-bitrate-quality"
        sx={{fontWeight: 500, ml: 0.5}}
      >
        {capitalize(bitrateQuality) || 'Unavailable'}
      </Typography>
    </Box>
  );
};

const EnvrName = ({envrName}) => {
  return (
    <Box sx={{display: 'flex'}}>
      <Typography
        className="card-child-envr-name"
        sx={{fontWeight: 500, mb: 0.5}}
      >
        {envrName || 'Name Unavailable'}
      </Typography>
    </Box>
  );
};

const EnvrMac = ({envrMac}) => {
  return (
    <Box sx={{display: 'flex'}}>
      <Typography sx={{width: '50%'}}>MAC:</Typography>
      <Typography
        className="card-child-envr-mac"
        sx={{fontWeight: 500, ml: 0.5}}
      >
        {envrMac || 'Unavailable'}
      </Typography>
    </Box>
  );
};

const LastConnected = ({lastConnected, timezoneName, applianceIsOnline}) => {
  const lastDateTime = DateTime.fromISO(lastConnected).setZone(timezoneName);
  const formatDate = lastDateTime?.isValid
    ? lastDateTime.toFormat('DDD')
    : 'Never Connected';

  return (
    <Box sx={{display: 'flex'}}>
      <Typography sx={{width: '50%'}}>
        {applianceIsOnline ? 'Connected Since:' : 'Last Connected:'}
      </Typography>
      <Typography
        className="card-child-last-connected"
        sx={{fontWeight: 500, ml: 0.5}}
      >
        {formatDate}
      </Typography>
    </Box>
  );
};

const OfflineCamerasList = ({cameras}) => {
  const allOnline = cameras.every((obj) => obj.isOnline);
  return (
    !allOnline && (
      <Box sx={{display: 'flex'}}>
        <Typography sx={{width: '50%'}}>Offline Cameras:</Typography>
        <OfflineCamerasModal cameras={cameras} />
      </Box>
    )
  );
};

const POSLastSync = ({posAllowed, pos, timezoneName}) => {
  return (
    posAllowed &&
    pos && (
      <Box sx={{display: 'flex'}}>
        <Typography sx={{width: '50%'}}>POS Last Sync:</Typography>
        <Typography className="card-child-pos" sx={{fontWeight: 500, ml: 0.5}}>
          {DateTime.fromISO(pos)
            .setZone(timezoneName)
            .toFormat('DDD')}
        </Typography>
      </Box>
    )
  );
};

const CAStatusList = ({caStatusAllowed, caStatus, cameras}) => {
  const allOnline = cameras.every((obj) => obj.cameraCaStatus === 'GOOD');
  return (
    caStatusAllowed &&
    caStatus &&
    !allOnline && (
      <Box sx={{display: 'flex'}}>
        <Typography sx={{width: '50%'}}>Cloud Archive:</Typography>
        <CloudArchiveModal cameras={cameras} />
      </Box>
    )
  );
};

const EarliestVideoList = ({cameras}) => {
  return (
    <Box sx={{display: 'flex'}}>
      <Typography sx={{width: '50%'}}>Oldest Video:</Typography>
      <EarliestVideoModal cameras={cameras} isSmallScreen />
    </Box>
  );
};

const AverageSpeed = ({bitrate}) => {
  const bitrateMbps = bitrate / 1000000;
  const truncatedBitrate = Math.trunc(bitrateMbps * 100) / 100; // 2 decimal places
  const uploadSpeed =
    bitrate > 0 ? `${truncatedBitrate.toFixed(2)} Mbps ` : 'Offline ';

  return (
    <Box sx={{display: 'flex'}}>
      <Typography sx={{width: '50%'}}>Average Speed:</Typography>
      <Typography
        className="card-child-bitrate"
        sx={{fontWeight: 500, ml: 0.5}}
      >
        {uploadSpeed}
      </Typography>
    </Box>
  );
};

const ImageHealthList = ({cameras, currentUser}) => {
  const [selectedCamera, setSelectedCamera] = useState(null);
  const openImpairmentModal = (camera) => {
    setSelectedCamera(camera);
  };
  const closeImpairmentModal = () => {
    setSelectedCamera(null);
  };
  const impairedCameras = cameras.filter((cam) => cam.isImpaired);
  return (
    impairedCameras.length > 0 && (
      <Box sx={{display: 'flex'}}>
        {selectedCamera && (
          <Dialog
            fullScreen
            open={!!selectedCamera}
            onClose={closeImpairmentModal}
          >
            <CameraTamperModal
              cameraData={selectedCamera}
              onClose={closeImpairmentModal}
              currentUser={currentUser}
            />
          </Dialog>
        )}
        <Typography sx={{width: '50%'}}>Impaired Cameras:</Typography>
        <Box sx={{overflow: 'hidden', textWrap: 'nowrap'}}>
          {impairedCameras.map((camera) => (
            <Box sx={{width: '50%'}} key={camera.id}>
              <Button
                sx={{p: 0}}
                className="card-child-impaired"
                onClick={() => openImpairmentModal(camera)}
              >
                {camera.name}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    )
  );
};

const RowActions = ({siteId, isEnvrAdmin, currentUser}) => {
  return (
    <Box sx={{display: 'flex'}}>
      <IconButton
        className="site-page-button"
        component={Link}
        to={`/sites/${siteId}`}
        sx={{mr: 1}}
        color="primary"
      >
        <PlaceIcon />
      </IconButton>
      {isEnvrAdmin && (
        <IconButton
          className="site-settings-button"
          component={Link}
          to={
            isNewNavigationWebEnabled(currentUser)
              ? `/home/site-settings/devices?siteId=${siteId}`
              : `/sites/${siteId}/settings/devices`
          }
          sx={{mb: 0.125}}
          color="primary"
        >
          <SettingsIcon />
        </IconButton>
      )}
    </Box>
  );
};

const MobileSiteCard = (props) => {
  const {
    name,
    status,
    offlineCameras,
    totalCameras,
    impairedCameras,
    pos,
    posStatus,
    earliestVideo,
    caStatus,
    bitrate,
    bitrateQuality,
    posAllowed,
    caStatusAllowed,
    timezoneName,
    siteId,
    currentUser,
    logger,
  } = props.data;
  const isEnvrAdmin = allowed(currentUser, [ENVR_ADMIN]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const contentRef = useRef(null);

  useEffect(
    () => {
      if (isExpanded && contentRef.current) {
        contentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    },
    [isExpanded],
  );

  const getAdditionalData = async (initData) => {
    const siteCameras = await getCamerasBySiteId(siteId);
    const additionalCamData = initData.reduce((acc, item) => {
      const updatedCameras = item.cameras.map((camera) => {
        const impairedCamera = siteCameras.find(
          (cam) => cam.id === `${camera.id}`,
        );
        return impairedCamera
          ? {
              ...camera,
              ...impairedCamera,
              hardwareId: impairedCamera.id,
              hardwareName: impairedCamera.name,
              hardwareStatus: impairedCamera.isImpaired
                ? 'Impaired'
                : 'Unimpaired',
              displayMessage: impairedCamera.isImpaired
                ? 'ImageAlert image issue detected'
                : 'ImageAlert image quality restored',
              earliestData: impairedCamera.earliestVideoGmt,
              applianceSensorNum: impairedCamera.channel,
            }
          : camera;
      });
      acc.push({
        ...item,
        cameras: updatedCameras,
      });
      return acc;
    }, []);
    return additionalCamData;
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let response = await getHealthDetailsForSite(siteId);
      const hasImpairedCameras = response.some((appliance) =>
        appliance.cameras?.find((cam) => cam.isImpaired === true),
      );
      if (hasImpairedCameras) {
        response = await getAdditionalData(response);
      }
      setData(response);
    } catch (error) {
      logger.error('Failed to load site data', {}, error);
    } finally {
      setIsLoading(false);
      setIsExpanded(true);
    }
  };

  const handleClick = () => {
    if (!isExpanded) {
      fetchData();
    } else {
      setIsExpanded(false);
    }
  };

  return (
    <Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 1,
        }}
      >
        <Typography
          className="card-site-name"
          variant="h5"
          sx={{alignSelf: 'center'}}
        >
          {name || 'Name Unavailable'}
        </Typography>
        {!isMobileApp() && (
          <RowActions
            siteId={siteId}
            isEnvrAdmin={isEnvrAdmin}
            currentUser={currentUser}
          />
        )}
      </Box>
      <Box sx={{mb: 2}} className="site-card">
        <ApplianceStatus status={status} />
        <OfflineCameras
          offlineCameras={offlineCameras}
          totalCameras={totalCameras}
        />
        <ImageHealth
          impairedCameras={impairedCameras}
          totalCameras={totalCameras}
        />
        <POS posAllowed={posAllowed} posStatus={posStatus} />
        <CloudArchiveStatus
          caStatusAllowed={caStatusAllowed}
          caStatus={caStatus}
        />
        <OldestVideo earliestVideo={earliestVideo} />
        <UploadSpeed bitrateQuality={bitrateQuality} />
      </Box>
      <Box sx={{borderTop: '1px solid gainsboro'}}>
        {isExpanded &&
          data.map((envr) => (
            <Box
              className="site-child-card"
              key={envr.envrName}
              ref={contentRef}
              sx={{
                py: 2,
                borderBottom: '1px solid gainsboro',
              }}
            >
              <React.Fragment>
                <EnvrName envrName={envr.envrName} />
                <EnvrMac envrMac={envr.envrMac} />
                <LastConnected
                  lastConnected={envr.lastConnected}
                  timezoneName={envr.timezoneName}
                  applianceIsOnline={envr.applianceIsOnline}
                />
                <OfflineCamerasList cameras={envr.cameras} />
                <POSLastSync
                  posAllowed={posAllowed}
                  pos={pos}
                  timezoneName={timezoneName}
                />
                <CAStatusList
                  caStatusAllowed={caStatusAllowed}
                  caStatus={caStatus}
                  cameras={envr.cameras}
                />
                <EarliestVideoList cameras={envr.cameras} />
                <AverageSpeed bitrate={bitrate} />
                <ImageHealthList
                  cameras={envr.cameras}
                  currentUser={currentUser}
                />
              </React.Fragment>
            </Box>
          ))}
        {isLoading ? (
          <LoadingContainer>
            <CircularProgress size="2rem" color="primary" />
          </LoadingContainer>
        ) : (
          <Button
            className="expand-collapse-button"
            onClick={handleClick}
            sx={{display: 'flex', m: '8px auto 0 auto', px: 4}}
            variant="text"
            endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            {isExpanded ? 'Show Less' : 'Show More'}
          </Button>
        )}
      </Box>
    </Card>
  );
};
export default MobileSiteCard;
