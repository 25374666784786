import React from 'react';
import PropTypes from 'prop-types';

import PageLoader from '../../../../shared/components/pageLoader';
import {
  createLocalUser,
  deleteLocalUser,
  getLocalUsers,
} from '../../../../api/localUsers';
import withSnackbar from '../../../../shared/components/snackbarSupport';
import SiteSettingsWrapperNewNavigation from '../utils/SiteSettingsWrapperNewNavigation';

const EnvysionLocalNewNavigation = ({snackbar}) => {
  const handleCreateLocalUser = async (newUser, siteId) => {
    try {
      const response = await createLocalUser(siteId, newUser);
      if (response.status && response.status.type === 'PARTIAL_SUCCESS') {
        snackbar.warning(`${response.status.message}`);
      } else {
        snackbar.success(`Created ${newUser.username} successfully`);
      }
    } catch (e) {
      snackbar.error(`Failed to create ${newUser.username}`);
      throw e;
    }
  };

  const handleDeleteLocalUser = async (localUser) => {
    if (localUser) {
      try {
        const response = await deleteLocalUser(
          localUser.siteUuid,
          localUser.id,
        );
        if (response.status && response.status.type === 'PARTIAL_SUCCESS') {
          snackbar.warning(`${response.status.message}`);
        } else {
          snackbar.success(`Deleted ${localUser.username} successfully`);
        }
      } catch (e) {
        snackbar.error(`Failed to delete ${localUser.username}`);
      }
    }
  };

  return (
    <SiteSettingsWrapperNewNavigation name="envysion-local-page-content">
      {(selectedSite, reload) => {
        return (
          <PageLoader
            key="envysionLocal"
            page={() => import('../envysionLocal')}
            reload={reload}
            onCreate={handleCreateLocalUser}
            onDelete={handleDeleteLocalUser}
            siteId={selectedSite.id}
            propsToIgnoreUpdate={['snackbar', 'onCreate', 'onDelete']}
            resources={{
              localUsers: async () => getLocalUsers(selectedSite.id),
            }}
          />
        );
      }}
    </SiteSettingsWrapperNewNavigation>
  );
};

EnvysionLocalNewNavigation.propTypes = {
  snackbar: PropTypes.shape({}).isRequired,
};
export default withSnackbar(EnvysionLocalNewNavigation);
