import React from 'react';
import PropTypes from 'prop-types';

import PageLoader from '../../../../shared/components/pageLoader';
import CapabilityGuard from '../../../../shared/components/capabilitiesGuard';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {INTEGRATION_ADMIN} from '../../../../shared/util/allowed';
import GlobalErrorPage from '../../../globalErrorPage';
import {
  getAdapterCategoryListSite,
  getAdaptersList,
  getIntegrationsDomain,
  getIntegrationsSite,
} from '../../../../api/integrations';
import {getAppliance, getAppliancesForSite} from '../../../../api/appliances';
import SiteSettingsWrapperNewNavigation from '../utils/SiteSettingsWrapperNewNavigation';

const IntegrationsNewNavigation = ({currentUser}) => (
  <SiteSettingsWrapperNewNavigation name="integrations-page-content">
    {(selectedSite, reload) => {
      return (
        <CapabilityGuard
          allowed={[INTEGRATION_ADMIN]}
          renderDefault={() => <GlobalErrorPage error="NoAuth" />}
        >
          <PageLoader
            key="site-integration"
            page={() => import('../integrations')}
            reload={reload}
            siteId={selectedSite.id}
            resources={{
              appliances: async () => {
                const appliances = await getAppliancesForSite(selectedSite.id);
                return Promise.all(
                  appliances.map(async (a) => ({
                    ...(await getAppliance(a.id)),
                    cameraDiscovery: a.cameraDiscovery,
                  })),
                );
              },
              integrationList: () => getIntegrationsSite(selectedSite.id),
              domainIntegrationList: () =>
                getIntegrationsDomain(currentUser.domainId),
              adapterList: () => getAdaptersList(),
              categoryList: () => getAdapterCategoryListSite(),
            }}
          />
        </CapabilityGuard>
      );
    }}
  </SiteSettingsWrapperNewNavigation>
);

IntegrationsNewNavigation.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
};
export default withCurrentUser(IntegrationsNewNavigation);
