import React, {useState, useMemo, useCallback} from 'react';
import {
  DataGridPro,
  GridToolbarQuickFilter,
  GridCellCheckboxRenderer,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {LicenseInfo} from '@mui/x-license';

const muiXKey = import.meta.env.VITE_MUIX_PRO;

if (muiXKey?.length) {
  LicenseInfo.setLicenseKey(muiXKey);
}

/**
 * Navigate the nested array structure (tree) and flatten it into a array usable by the data grid component
 * @param {array} siteHierarchy
 * @returns {object} returns an object containing transformed data which fits the format expected by the data grid chart
 */
const getRowDataMap = (siteHierarchy) => {
  const rowData = [];
  const uniqueSiteIds = new Set();
  const rowDataMap = {};

  /**
   * @param {object} node - current item being processed.
   * @param {array} hierarchy - represents path to node.
   */
  const traverse = (node, hierarchy) => {
    // create a copy of the hierarchy array, with name of current node appended, this keeps a record of the path within the data structure
    const currentHierarchy = node.id === '-1' ? [] : [...hierarchy, node.name];

    const cleanDuplicateCategories = (names, catName) => {
      // if category name exists append the category name with a space at the end
      if (names.includes(catName)) {
        return cleanDuplicateCategories(names, `${catName} `);
      }
      // category name doesnt exist already
      return catName;
    };
    // Create a copy of the categories to avoid mutating the original data
    rowDataMap[node.id] = {...node, categories: {}, sites: {}};

    const prevNames = [];

    node.categories?.forEach((category) => {
      let categoryName = '';

      const newCatName = cleanDuplicateCategories(prevNames, category.name);
      prevNames.push(newCatName);

      categoryName = newCatName;
      const categoryHierarchy = currentHierarchy.concat(categoryName);
      traverse(category, currentHierarchy);

      rowDataMap[category.id] = {
        ...category,
        hierarchy: categoryHierarchy,
        parentId: node.id,
        categories: category.categories.reduce(
          (acc, c) => ({...acc, [c.id]: rowDataMap[c.id]}),
          {},
        ),
        sites: category.sites?.reduce((acc, s) => {
          const site = {
            ...s,
            id: `${s.id}-s`, // guard against potential category id collisions
            hierarchy: categoryHierarchy.concat(s.name),
            parentId: category.id,
          };
          // guard against duplicate ids from multi appliance sites allowing mismatched categories
          if (!uniqueSiteIds.has(site.id)) {
            rowData.push(site);
          }
          uniqueSiteIds.add(site.id);
          acc[site.id] = site;
          return acc;
        }, {}),
      };

      // add to parent
      rowDataMap[node.id].categories[category.id] = rowDataMap[category.id];
      rowData.push(rowDataMap[category.id]);
    });
  };

  traverse(
    {
      id: '-1',
      categories: siteHierarchy,
    },
    [],
  );

  return {
    rowData, // needed to maintain sorting order
    rowDataMap: rowData.reduce((acc, c) => {
      return {...acc, [c.id]: c};
    }, {}),
  };
};

const getNestedIds = (tree) => {
  return [
    ...Object.values(tree.categories).map((category) => [
      category.id,
      ...getNestedIds(category),
    ]),
    ...Object.keys(tree.sites),
  ].flat();
};

const getParentCategoryIds = (categoryId, rowDataMap) => [
  categoryId,
  ...(categoryId === '-1'
    ? []
    : getParentCategoryIds(rowDataMap[categoryId].parentId, rowDataMap)),
];

const QuickSearchToolbar = () => (
  <Box
    sx={{
      p: 0.5,
      pb: 0,
    }}
  >
    <GridToolbarQuickFilter autoFocus />
  </Box>
);

const gridColDefSettings = {
  width: 400,
  filterable: true,
  disableColumnMenu: true,
  hideDescendantCount: true,
  valueGetter({rowNode}) {
    return rowNode.groupingKey;
  },
};

const slotsSettings = {
  toolbar: QuickSearchToolbar,
};

export default function SiteHierarchyGridFilter(props) {
  const {data, onChange, selectedSites, gridStyles} = props;

  const {rowData, rowDataMap} = useMemo(
    () => getRowDataMap(Object.values(data)),
    [data],
  );

  const initialSelection = () => {
    if (rowDataMap) {
      const selectionSet = new Set();

      selectedSites.forEach((id) => {
        const site = `${id}-s`;
        if (rowDataMap[site]) {
          selectionSet.add(site);
          const parents = getParentCategoryIds(
            rowDataMap[site].parentId,
            rowDataMap,
          );
          parents.forEach((parent) => {
            if (parent !== '-1') selectionSet.add(parent);
          });
        }
      });

      const selections = rowData
        .filter((row) => selectionSet.has(row.id))
        .map((i) => {
          return i.id ? i.id : i;
        });

      return selections;
    }
    return [];
  };

  const [isFiltering, setIsFiltering] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(initialSelection);

  /**
   * Handles the selection and de-selection of items in a hierarchical structure
   * @param {array} selectedItem - array of ids of the selected items
   * @returns {array} of selected items (and their children where applicable)
   */

  const handleItemSelection = useCallback(
    (selectedItems) => {
      const addedItemId = selectedItems.filter(
        (id) => !currentSelection.includes(id),
      )[0];

      const removedItemId = currentSelection.filter(
        (id) => !selectedItems.includes(id),
      )[0];

      const newSelection = new Set(currentSelection);
      const selectionItem = addedItemId || removedItemId;
      const isCategory =
        selectionItem.substring(selectionItem.length - 2) !== '-s';
      const parentCategoryIds = getParentCategoryIds(
        rowDataMap[selectionItem].parentId,
        rowDataMap,
      );

      if (addedItemId) {
        newSelection.add(addedItemId);

        if (isCategory) {
          // add children
          const nestedIds = getNestedIds(rowDataMap[addedItemId]);
          nestedIds.forEach((id) => newSelection.add(id));
        }

        // add parent categories if all children selected
        for (let i = 0; i < parentCategoryIds.length; i += 1) {
          const parentId = parentCategoryIds[parentCategoryIds.length - 1];
          if (parentId === '-1') break;

          const nestedIds = getNestedIds(rowDataMap[parentId]);
          if (nestedIds.every((id) => newSelection.has(id))) {
            newSelection.add(parentId);
          } else {
            break; // want to break early so we don't go up entire nested tree unnecessarily
          }
        }

        parentCategoryIds.forEach((parentId) => {
          if (parentId !== '-1') {
            const nestedIds = getNestedIds(rowDataMap[parentId]);

            if (nestedIds.every((id) => newSelection.has(id))) {
              newSelection.add(parentId);
            }
          }
        });
      }

      if (removedItemId) {
        newSelection.delete(removedItemId);

        if (isCategory) {
          // remove children
          const nestedIds = getNestedIds(rowDataMap[removedItemId]);
          nestedIds.forEach((id) => newSelection.delete(id));
        }
        // remove parent categories
        parentCategoryIds.forEach((id) => newSelection.delete(id));
      }

      const sites = Array.from(newSelection).reduce((acc, id) => {
        const isSite = id?.substring(id.length - 2) === '-s';

        if (isSite) {
          acc.push(id.substring(0, id.length - 2));
        }
        return acc;
      }, []);

      setCurrentSelection(Array.from(newSelection));
      onChange(sites);
    },
    [onChange, rowDataMap, currentSelection],
  );

  const handleGetPath = useCallback((row) => row.hierarchy, []);

  const columnDef = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        renderCell: (params) => {
          const {rowNode} = params;
          const {isAutoGenerated, type} = rowNode;
          const isCategory = type === 'group';

          if (isAutoGenerated) return <GridCellCheckboxRenderer {...params} />;

          const children = isCategory && getNestedIds(rowDataMap[rowNode.id]);

          const partiallySelectedCategory =
            children &&
            children.some((child) => currentSelection.includes(child)) &&
            children.some((child) => !currentSelection.includes(child));

          return (
            <GridCellCheckboxRenderer
              {...params}
              indeterminate={partiallySelectedCategory}
            />
          );
        },
      },
    ],
    [currentSelection, rowDataMap],
  );

  return (
    <DataGridPro
      data-cy="data-grid-tree-filter-main-component"
      rows={rowData}
      columns={columnDef}
      slots={slotsSettings}
      columnHeaderHeight={0}
      filterable
      checkboxSelection
      onFilterModelChange={(f) => {
        setIsFiltering(!!f.quickFilterValues.length);
      }}
      treeData
      getTreeDataPath={handleGetPath}
      rowSelectionModel={currentSelection}
      onRowSelectionModelChange={handleItemSelection}
      keepNonExistentRowsSelected
      groupingColDef={gridColDefSettings}
      defaultGroupingExpansionDepth={isFiltering ? -1 : 1}
      isRowSelectable={(params) => params.row.parentId !== '-1'}
      sx={gridStyles}
      disableRowSelectionOnClick
      hideFooter
    />
  );
}

SiteHierarchyGridFilter.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      results: PropTypes.arrayOf(PropTypes.shape([])),
      categories: PropTypes.arrayOf(PropTypes.shape()),
    }),
  ),
  selectedSites: PropTypes.arrayOf(PropTypes.any), // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
  gridStyles: PropTypes.shape({}),
};

SiteHierarchyGridFilter.defaultProps = {
  selectedSites: [{}],
  data: [{}],
  onChange: () => {},
  gridStyles: {},
};
