import React, {Children} from 'react';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import DashboardComponent from '../../shared/components/dashboardComponent';
import SspSection from './landingPage/SspSection';
import AlarmConfigurationPageContent from '../admin/alarmConfiguration/AlarmConfigurationPageContent';
import {hasRequiredCapabilityWithMatchingPreference} from '../../shared/components/navigationBar/utils';
import {MOTION_ALARM_ADMIN, MOTION_ALARM_USER} from '../../shared/util/allowed';
import AlarmEventsSummaryNewNavigation from '../sites/details/alarmEventsSummaryNewNavigation';
import AccessControlNewNavigation from '../sites/details/accessControl/accessControlNewNavigation';

const dashboard = () => (
  <DashboardComponent data-cy="ssp-landing-page-content">
    {Children.toArray(<SspSection />)}
  </DashboardComponent>
);
const alarmConfig = (props) => <AlarmConfigurationPageContent {...props} />;

const accessControl = () => <AccessControlNewNavigation />;

const alarmEvents = () => <AlarmEventsSummaryNewNavigation />;

export const getTabs = (currentUser) =>
  [
    {
      key: 'sspLandingPage',
      path: 'landing-page',
      primaryTitleText: 'Dashboard',
      component: dashboard,
      icon: <DashboardOutlinedIcon />,
      capabilities: [MOTION_ALARM_USER, MOTION_ALARM_ADMIN],
      preferences: [],
    },
    {
      key: 'alarmEvents',
      path: 'alarm-events',
      primaryTitleText: 'Alarm Events',
      component: alarmEvents,
      icon: <SummarizeOutlinedIcon />,
      capabilities: [MOTION_ALARM_USER, MOTION_ALARM_ADMIN],
      preferences: [],
    },
    {
      key: 'accessControl',
      path: 'access-control',
      primaryTitleText: 'Access Control',
      component: accessControl,
      icon: <KeyOutlinedIcon />,
      capabilities: [],
      preferences: [{altaLink: true}],
    },
    {
      key: 'alarmConfiguration',
      path: 'alarm-configuration',
      primaryTitleText: 'Alarm Configuration',
      shortTitleText: 'Configuration',
      component: withCurrentUser(alarmConfig),
      icon: <TuneOutlinedIcon />,
      capabilities: [MOTION_ALARM_ADMIN],
      preferences: [{enableAlarmSiteAnalysis: true}],
    },
  ].filter((item) =>
    hasRequiredCapabilityWithMatchingPreference(item, currentUser),
  );
