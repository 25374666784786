import React, {Fragment} from 'react';
import {Typography, Button, Box, Alert} from '@mui/material';
import PropTypes from 'prop-types';
import {kebabCase} from 'lodash';
import {Link} from 'react-router-dom';

import DashboardWidget from '../dashboardWidget';

const getRowStyle = (rowType) => {
  switch (rowType) {
    case 'main':
      return {fontWeight: 600};
    case 'secondary':
      return {color: (theme) => theme.palette.text.secondary};
    default:
      return undefined;
  }
};

const AltWidget = ({altContent, isError}) => {
  const css = altContent.dualPanel
    ? {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: '50%',
      }
    : {width: '100%'};
  return (
    <Fragment>
      {altContent.panels.map((data) => (
        <Box
          key={kebabCase(data.title)}
          sx={{
            ...css,
            borderRight: data.divider && '1px solid gainsboro',
          }}
        >
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            {data.title ? (
              <Typography
                variant="h6"
                sx={{
                  ml: altContent.dualPanel && data.icon && 2,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {data.title}
              </Typography>
            ) : (
              // Required for spacing
              <Typography variant="h6" sx={{visibility: 'hidden'}}>
                ` `
              </Typography>
            )}

            {data.icon}
          </Box>
          {isError ? (
            <Alert id={`error-${kebabCase(data.title)}`} severity="error">
              Error loading widget
            </Alert>
          ) : (
            <Box sx={{textAlign: 'center'}}>
              <Typography
                id={`${kebabCase(data.title)}-${kebabCase(data.buttonText)}`}
                sx={{fontSize: '60px', lineHeight: 1.2, mt: 2}}
              >
                {data.count}
              </Typography>
              <Button
                sx={{p: 0}}
                component={Link}
                data-cy={`${kebabCase(data.buttonText)}-${kebabCase(
                  data.title,
                )}-button`}
                to={{
                  pathname: data.path,
                  search: data.search,
                  state: data.state,
                }}
                target={data.target}
              >
                {data.buttonText}
              </Button>
              <Typography
                id={`last-day-${kebabCase(data.title)}`}
                sx={{textAlign: 'center', mt: 2}}
              >
                {data.message}
              </Typography>
            </Box>
          )}
        </Box>
      ))}
    </Fragment>
  );
};

const getContent = (data, isSmallScreen) => (
  <Fragment>
    {data &&
      data.map((row) => (
        <Box
          data-cy="widget-content-line"
          id={row.id}
          key={row.id}
          alignItems="center"
          display="flex"
        >
          {isSmallScreen ? (
            <Typography
              sx={{
                display: 'flex',
                flex: 1,
                marginRight: (theme) => theme.spacing(1.5),
              }}
              variant="caption"
            >
              {row.label}
            </Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                marginRight: (theme) => theme.spacing(1.5),
                minHeight: 32,
              }}
            >
              {row.label}
            </Box>
          )}
          {row.value !== undefined ? (
            <Typography
              sx={{
                marginLeft: (theme) => theme.spacing(1.5),
                ...{...getRowStyle(row.type)},
              }}
              data-cy="widget-content-line-data"
              variant="subtitle1"
            >
              {row.value}
            </Typography>
          ) : null}
        </Box>
      ))}
  </Fragment>
);

const DashboardStatusWidget = ({
  content,
  altContent,
  isError,
  title,
  icon,
  loading,
  buttonIcon,
  buttonLabel,
  withActions,
  isSmallScreen,
  pathname,
  search,
  target,
  ...rest
}) => (
  <DashboardWidget
    id={kebabCase(title)}
    title={title}
    icon={icon && React.createElement(icon, {'data-cy': 'widget-icon'})}
    loading={loading}
    content={getContent(content, isSmallScreen)}
    altContent={
      altContent && <AltWidget altContent={altContent} isError={isError} />
    }
    {...withActions && {
      actions: (
        <Button
          component={Link}
          data-cy="widget-action"
          to={{
            pathname,
            search,
            state: {fromDashboard: true},
          }}
          target={target}
          endIcon={
            buttonIcon &&
            React.createElement(buttonIcon, {
              'data-cy': 'widget-action-icon',
            })
          }
          sx={{color: (theme) => theme.palette.primary.main}}
        >
          {buttonLabel}
        </Button>
      ),
    }}
    isSmallScreen={isSmallScreen}
    {...rest}
  />
);

DashboardStatusWidget.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.elementType,
  loading: PropTypes.bool.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.string,
    }),
  ),
  buttonIcon: PropTypes.elementType,
  buttonLabel: PropTypes.string,
  withActions: PropTypes.bool,
  isSmallScreen: PropTypes.bool,
  path: PropTypes.string,
  search: PropTypes.string,
  target: PropTypes.string,
};
DashboardStatusWidget.defaultProps = {
  title: undefined,
  content: undefined,
  icon: undefined,
  buttonIcon: undefined,
  buttonLabel: undefined,
  withActions: true,
  isSmallScreen: false,
  path: undefined,
  search: undefined,
  target: undefined,
};
export default DashboardStatusWidget;
